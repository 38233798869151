import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { runEngine } from "../../../framework/src/RunEngine";
// Customizable Area Start
import MessageEnum, { getName } from "../../../framework/src/Messages/MessageEnum";
import { getStorageData, removeStorageData, setStorageData } from "../../../framework/src/Utilities";
import { checkAccountLimitError } from "../../../blocks/utilities/src/ResolveCondition";
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Startundefined
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  viewMoreClassification: boolean;
  loading:boolean
  publicationDetails: {
    search_parameters: {
        patent_id: string
    },
    title: string,
    publication_year: string,
    full_view_url: string,
    authors: string[],
    classifications: [
        {
            code: string,
            description: string,
        }
    ],
    similar_documents: [
      {
        is_scholar: boolean,
        scholar_id: string,
        is_patent: boolean,
        publication_number: string,
        scholar_authors: string,
        publication_date: string,
        title: string,
        patent_id: string,
        serpapi_link: string,
      }
    ],
    snippet: string
},
isAccountLimitExceeded: boolean
  // Customizable Area End
}

interface SS {
  id: any;
}

export default class ClassificationPageController extends BlockComponent<
  Props,
  S,
  SS
> {
  apiGetPublicationDetailsCallId: string = ""
  apiGetDetailsCallId: string = ""
  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceDataMessage),
      getName(MessageEnum.SessionSaveMessage),
      getName(MessageEnum.SessionResponseMessage),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.NavigationKeyFeatureMessage),
    ];

    this.state = {
      viewMoreClassification: false,
      loading: false,
      publicationDetails:{
        search_parameters: {
          patent_id: ""
        },
        title: "",
        publication_year: "",
        full_view_url: "",
        authors: [],
        classifications: [
          {
            code: "",
            description: "",
          }
        ],
        similar_documents: [
          {
            is_scholar: false,
            scholar_id: "",
            is_patent: false,
            publication_number: "",
            scholar_authors: "",
            publication_date: "",
            title: "",
            patent_id: "",
            serpapi_link: "",
          }
        ],
        snippet: ""
      },
      isAccountLimitExceeded: false
    };

    // Customizable Area End
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    runEngine.debugLog("Message Recived", message);
    const apiRequestCallId = message.getData(
      getName(MessageEnum.RestAPIResponceDataMessage)
    );

    const responseJson = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );

    if (apiRequestCallId === this.apiGetPublicationDetailsCallId) {
      if(!responseJson?.errors?.length) {        
        this.setState({publicationDetails: responseJson, loading: false})
      } 
      else {
        this.setState({
          loading: false,
          isAccountLimitExceeded: checkAccountLimitError(responseJson?.error,responseJson?.errors)
        })
      }
    } 
    if(apiRequestCallId === this .apiGetDetailsCallId) {
      if(!responseJson?.errors?.length) {
        this.setState({publicationDetails: responseJson,loading: false})
      }
      else{
        this.setState({
          loading:false,          
          isAccountLimitExceeded: checkAccountLimitError(responseJson?.error,responseJson?.errors)
        })
      }
    }
    // Customizable Area End
  }

  // Customizable Area Start

  async componentDidMount() {
    const BackToDetails = await getStorageData("BackToDetails")
    if(BackToDetails) {
      this.getDetailsAPI()
    } else {
      const publicationId = this.props.navigation.getParam("id")
      setStorageData("publicationId", publicationId)    
      this.getPublicationDetailsAPI(publicationId)
    }
  }
  goBackToPublicationPage = async () => {
    removeStorageData("BackToDetails")
    this.props.navigation.goBack();
  };

  getPublicationDetailsAPI = async (publicationId: string) => {
    this.setState({ loading: true })
    const header = {
      token: await getStorageData("token"),
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.apiGetPublicationDetailsCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.getPublicationDetailsAPIEndPoint}?scholar_id=scholar/${publicationId}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  getDetailsAPI = async () => {
    this.setState({ loading: true })
    const header = {
      token: await getStorageData("token"),
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.apiGetDetailsCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getDetailsPublicationAPIEndPoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  viewMoreClassificationFun = () => {
    this.setState({ viewMoreClassification: true })
  };

  hideMoreClassificationFun = () => {
    this.setState({ viewMoreClassification: false })
  };

  goToShowMappingPage = async () => {

    const data = {
      publication_number: this.state.publicationDetails?.search_parameters?.patent_id
  }
    const message = new Message(getName(MessageEnum.NavigationMessage));
    setStorageData("scholar_id", data.publication_number);
    message.addData(getName(MessageEnum.NavigationTargetMessage), "PublicationShowMapping");
    const raiseMessage: Message = new Message(
      getName(MessageEnum.NavigationPayLoadMessage)
    )
    raiseMessage.addData(getName(MessageEnum.NavigationMessage), data);
    message.addData(getName(MessageEnum.NavigationRaiseMessage), raiseMessage);
    message.addData(getName(MessageEnum.NavigationPropsMessage), this.props)
    this.send(message)

  };
  // Customizable Area End
}
