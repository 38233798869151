import React from "react";
// Customizable Area Start
import { Box, Checkbox, Dialog, DialogActions, DialogContent, DialogTitle, FormControl, Grid, IconButton, InputAdornment, InputBase, LinearProgress, ListItemText, MenuItem, Select, Switch, TextField, TextareaAutosize, Theme, ThemeProvider, Typography, createStyles, createTheme, styled, withStyles } from "@material-ui/core";
import Header from "../../../components/src/Header.web";
import Footer from "../../../components/src/footer.web";
import { dropdown, plusIcon, avtarLogo, assigneeLogo, searchIcon, uploadIcon, uncheckedIcon, checkedIcon, historyIcon, rightArrow, joinIcon, arrowIcon, roundRightArrow, roundLeftArrow, closeIcon, uploadDoc, browserPlusIcon, textPage, placeHolderImage } from "./assets";
import { MuiPickersUtilsProvider, KeyboardDatePicker } from '@material-ui/pickers';
import MomentUtils from "@date-io/moment";
import moment from "moment";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";
import Skeleton from '@material-ui/lab/Skeleton';
import { ArticlesResponse, configJSON } from "./LandingPageController";
import Loader from "./Loader.web";
import { Link } from "react-router-dom"

interface CustomArrowProps {
  className?: string;
  style?: React.CSSProperties;
  onClick?: () => void;
}

const SuccessDialog = styled(Dialog)({
  "& .MuiPaper-root": {
    width: "50%",
    maxWidth: "none",
    "@media (max-width: 760px)": {
      width: "80%",
    },
    "@media (max-width: 450px)": {
      width: "95%",
    },
  },
  "& .delete-modal": {
    background: '#F5F5F4',
    padding: "40px 60px",
    borderRadius: 0,
    "@media (max-width: 760px)": {
      padding: "40px 30px",
    },
    "@media (max-width: 450px)": {
      padding: "40px 10px",
    },
  },
  "& .backdrop-modal-delete": {
    backgroundColor: 'rgba(15, 23, 42, 0.4)',
  },
  "& .modal-article-image": {
    width: "100%",         
    height: "auto",        
    maxHeight: "50vh",     
    objectFit: "contain",  
  },
  "& .modal-title": {
    color: "#333",  
    fontFamily: "Poppins",
    fontSize: 24,
    fontWeight: "bold", 
    marginBottom: 10, 
    padding: "5px" ,
   
    "@media (max-width: 760px)": {
      textAlign: "center",
    },
  },
  "& .modal-description": {
    fontFamily: "Poppins",
    fontSize: 16, 
    color: "#4F5356", 
    lineHeight: 1.6, 
    marginBottom: 20, 
    "@media (max-width: 760px)": {
      textAlign: "center",
    },
  },
  "& .modal-date": {
    fontFamily: "Poppins",
    fontSize: 14, 
    color: "#777", 
    fontStyle: "italic", 
    "@media (max-width: 760px)": {
      textAlign: "center",
    },
  },
  "& .dailog-Box": {
    display: "flex",
    alignItems: 'center',
    flexDirection: 'column',
    gap: 30,
  },
  "& .closeIconStyle": {
    position: 'absolute',
    top: 0,
    right: 0,
  },
});




/* istanbul ignore next */
const NextArrow = (props: CustomArrowProps) => {
  return (
    <div
      className={"slick-arrow slick-next-custom"}
      style={{ ...props.style, ...webStyle.next }}
      onClick={props.onClick}
    >
      <img src={roundRightArrow} alt="roundRightArrow" style={{width:"36px"}}/>
    </div>
  );
};

/* istanbul ignore next */
const PrevArrow = (props: CustomArrowProps) => {
  return (
    <div
      style={{ ...props.style, ...webStyle.prev }}
      className="slick-arrow slick-prev-custom"
      onClick={props.onClick}
    >
      <img src={roundLeftArrow} alt="roundLeftArrow"  style={{width:"36px"}}/>
    </div>
  );
};

const settings = {
  dots: false,
  infinite: false,
  speed: 500,
  slidesToShow: 4,
  slidesToScroll: 1,
  cssEase: "linear",
  nextArrow: <NextArrow />,
  prevArrow: <PrevArrow />,
  centerPadding: "20px",
  autoplay: false,
  responsive: [
    {
      breakpoint: 1441,
      settings: {
        slidesToShow: 3,
      }
    },
    {
      breakpoint: 1051,
      settings: {
        slidesToShow: 2,
      }
    },
    {
      breakpoint: 651,
      settings: {
        slidesToShow: 1,
      }
    }
  ]
};

// Customizable Area End

import LandingPageController, {
  Props,
} from "./LandingPageController";
import { event } from "react-native-reanimated";

export default class LandingPage extends LandingPageController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  handleRenderValue = (selected:string, renderVal: string) => {
   return selected ? <Box>{selected}</Box> : <Box className="selectedColor">{renderVal}</Box>
  }
  /* istanbul ignore next */
  serachBoxMain = () => {
    return <Box className="searchBox-Main">
      <Grid container style={{marginTop:"-12px"}}>
        <Grid item xs={12}>
          <Box className="main-field">
            <TextField
              className="FormTextField"
              placeholder="Search here"
              variant="outlined"
              id="serchText"
              fullWidth
              multiline
              rows={3}
              value={this.state.searchString}
              onChange={(event) => this.handleSearchChange(event)}
              inputProps={{
                className: "FormTextField-placeholder"
              }}
              InputProps={{
                className: "FormTextField-style",
                startAdornment: (
                  <InputAdornment position="start">
                    <img src={searchIcon} alt="searchIcon" className="searchIconStyle" />
                  </InputAdornment>
                )
              }}
            />
            {this.state.docUploadTextField?.name &&
              <>
                <LinearProgress variant="determinate" value={this.state.progress} className="progressStyle" />
                <Box className="uploadDoc-main-box-search">
  <Box className="doc-box-search">
    <Box className="doc-img-box-search">
      <img src={textPage} height={24} width={24} alt="textPage" />
    </Box>
    <Box>
      <Box className="file-name-search">
        {this.state.docUploadTextField ? this.state.docUploadTextField.name : 'No file uploaded'}
      </Box>
      <Box className="file-Time-search">
        {this.state.uploadTimeLeft !== null ? `${this.state.uploadTimeLeft}s left` : 'Upload complete'}
      </Box>
    </Box>
    <Box className="doc-img-search">
      <img
        src={closeIcon}
        height={24}
        width={24}
        alt="closeIcon"
        id="remove-file"
        onClick={this.handleRemoveFile} 
      />
    </Box>
  </Box>
</Box>
              </>
            }
            <InputAdornment position="end" className="Icon-Button">
              <button className="uploadIconBtnStyle">
                <img src={uploadIcon} alt="uploadIcon" id="open-doc" onClick={() => this.docModelopen()} />
              </button>
            </InputAdornment>
          </Box>
        </Grid>
        <Grid item xs={12} className="search-grid">
            <Box className="search-box-container">
              {this.state.boxArr.map((item: { name: string }, index: number) => (
                <Box className="main-box">
                  <Box className="search-checkbox-main">
                    <Checkbox
                      checked={this.state.filterCheckbox.includes(item.name)}
                      value={item.name}
                      id="checkBoxFiler"
                      onChange={(event) => this.handleCheckBoxChange(event)}
                      checkedIcon={
                        <img
                          src={checkedIcon}
                          alt="barcodeIp"
                        />
                      } icon={
                        <img
                          src={uncheckedIcon}
                          alt="barcodeIp"
                        />
                      } color="primary" className="checkBoxStyle" />
                    <Box key={index} className="box-name">{item.name}</Box>
                  </Box>
                </Box>
              ))}
            </Box>
          </Grid>
            <UploadDocDialog
    open={this.state.docModel}
    onClose={this.docModelClose}
    id="uploadDoc"
    aria-labelledby="alert-dialog-title"
    fullWidth
    aria-describedby="alert-dialog-description"
    PaperProps={{
      className: this.state.docUpload?.name ? "" : "uploadDoc-modal",
      elevation: 0
    }}
    BackdropProps={{
      className: "backdrop-modal",
    }}
  >
    <Box className={this.state.docUpload?.name ? "closeIconStyle-upload" : "closeIconStyle"}>
      <IconButton aria-label="close">
        <img src={closeIcon} height={24} width={24} alt="closeIcon" id="uploadClose" onClick={() => this.docModelClose()} />
      </IconButton>
    </Box>
    {this.state.docUpload?.name ? (
      <>
        <DialogTitle className="dailog-title-style" id="customized-dialog-title">
          Uploading Files
        </DialogTitle>
        <DialogContent dividers>
          <LinearProgress variant="determinate" value={this.state.progress} className="progressStyle" />
          <Box className="uploadDoc-main-box-inner">
        <Box className="doc-box">
          <Box className="doc-img-box">
            <img src={textPage} height={24} width={24} alt="textPage" />
          </Box>
          <Box>
            <Box className="file-name">{this.state.docUpload?.name}</Box>
            {this.state.uploadTimeLeft !== null && (
              <Box className="file-Time">{`${this.state.uploadTimeLeft}s left`}</Box>
            )}
          </Box>
          <Box className="doc-img" onClick={this.handleRemoveFile}>
            <img src={closeIcon} height={24} width={24} alt="closeIcon" />
          </Box>
        </Box>
              </Box>
        </DialogContent>
        <DialogActions className="doc-action">
          <Grid item>
            <button className="cancelBtn" id="clickOnClose" onClick={() => this.docModelClose()}>
              Cancel
            </button>
          </Grid>
          <Grid item>
            <button className="uploadBtn" id="clickUploadFile" onClick={() => this.handleUploadClick()}>
              Upload
            </button>
          </Grid>
        </DialogActions>
      </>
    ) : (
      <Box 
        className="box-upload" 
        onDragOver={this.handleDragOver} 
        onDragLeave={this.handleDragLeave} 
        onDrop={this.handleDrop} 
      >
        <Box className="uploadDoc-main-box">
          <img src={uploadDoc} height={48} width={48} alt="uploadDoc" className="uploadDoc-style" id="upload-img" onClick={this.handleUpload} />
          <input 
            type="file" 
            className="uploadPhotoStyle" 
            accept=".pdf" 
            id="file-input" 
            ref={this.fileInputRef} 
            onChange={this.handleUploadDoc} 
            style={{ display: 'none' }} 
          />
          <Box className="model-titel-box">
            <Box className="title-model">Upload Document</Box>
            <Box className="model-Text">Drag and drop the files here</Box>
          </Box>
          <Box className="browser-box">
            <img src={browserPlusIcon} height={20} width={20} alt="browserPlusIcon" className="uploadDoc-style" id="upload-img" onClick={this.handleUpload} />
            <Box className="model-browser">
              <input 
                type="file" 
                className="uploadPhotoStyle" 
                accept=".pdf" 
                id="file-input" 
                ref={this.fileInputRef} 
                onChange={this.handleUploadDoc} 
                style={{ display: 'none' }} 
              />
              <label htmlFor="file-input" style={{ cursor: 'pointer' }}> Browse </label>
            </Box>
          </Box>
        </Box>
      </Box>
    )}
        </UploadDocDialog>

        <Grid item xs={12} style={{marginTop:"-20px"}}>
          <button   className={this.getSearchButtonClassName()}   disabled={this.checkGenerateKeyString()}  id="new-search-btn" onClick={() => this.handleClickNewSearch()}>
            Generate Key Strings
          </button>
        </Grid>
        <Grid item xs={12}>
          <Box className="advance-search-buttons">
          <Box className="advance-search">
            <Box className="advance-search-text" id="landing-heading">
              Advance Search
            </Box>
            <Box>
              <IOSSwitch name="IOSSwitch" checked={this.state.toggleSwitch} onChange={() => this.toggleIOSSwitch()} className="toggle_button" id="toggleBtn" />
            </Box>
          </Box>
          <Box>
            {this.state.toggleSwitch && <button className="clear-all-button" id="clearAllId" onClick={() => this.handleClearAll()}>Clear All</button>}
          </Box>
          </Box>
        </Grid>
      </Grid>
      {this.state.toggleSwitch ? (
        <Box className="white-container">
          <Grid container>
            <Grid item xs={12}>
              <Box className="heading-advanceSearch" id="show-box">
                Advance Search
              </Box>
            </Grid>
            <Grid item xs={12}>
              <Box className="dateRange-container">
                <Box className="dateRange-main-box">
                  <Grid container spacing={2} className="dateRange-main-grid">
                    <Grid item xs={12} sm={6} md={3}>
                      <Box className="dateRange-text">Date Range</Box>
                    </Grid>
                    <Grid item xs={12} sm={6} md={3}>
                      <FormControl fullWidth className="selectBoxWrap">
                        <Select
                          value={this.state.priority}
                          input={<BootstrapInput />}
                          id="iconPropsId"
                          IconComponent={(props) => <img {...props} src={dropdown} alt="dropdown" />}
                          displayEmpty
                          renderValue={(selectedPriority) => (
                            this.handleRenderValue(selectedPriority as string, "Priority")
                          )}
                          MenuProps={{
                            PaperProps: {
                              style: { marginTop: 4 }
                            },
                            MenuListProps: {
                              style: { padding: 4, background: "white" }
                            },
                            anchorOrigin: {
                              vertical: "bottom",
                              horizontal: "left"
                            },
                            transformOrigin: {
                              vertical: "top",
                              horizontal: "left"
                            },
                            getContentAnchorEl: null
                          }}
                        >
                          <StyledMenuItemLanguage className="dateRange-menu-style" value="Priority">
                            <Checkbox
                              checked={this.state.priority === "Priority"}
                              checkedIcon={<img src={checkedIcon} alt="checked" />}
                              icon={<img src={uncheckedIcon} alt="unchecked" />}
                              value={"Priority"}
                              id="PriorityId"
                              onChange={(event) => this.handlePriorityChange(event)}
                            />
                            <ListItemText primary="Priority" />
                          </StyledMenuItemLanguage>

                          <StyledMenuItemLanguage className="dateRange-menu-style" value="Filing">
                            <Checkbox
                              checked={this.state.priority === "Filing"}
                              checkedIcon={<img src={checkedIcon} alt="checked" />}
                              icon={<img src={uncheckedIcon} alt="unchecked" />}
                              value={"Filing"}
                              onChange={(event) => this.handlePriorityChange(event)}
                            />
                            <ListItemText primary="Filing" />
                          </StyledMenuItemLanguage>

                          <StyledMenuItemLanguage className="dateRange-menu-style" value="Publication">
                            <Checkbox
                              checked={this.state.priority === "Publication"}
                              checkedIcon={<img src={checkedIcon} alt="checked" />}
                              icon={<img src={uncheckedIcon} alt="unchecked" />}
                              value={"Publication"}
                              onChange={(event) => this.handlePriorityChange(event)}
                            />
                            <ListItemText primary="Publication" />
                          </StyledMenuItemLanguage>
                        </Select>
                      </FormControl>
                    </Grid>
                    <Grid item xs={12} sm={6} md={3}>
                      <ThemeProvider theme={theme}>
                        <MuiPickersUtilsProvider utils={MomentUtils}>
                          <KeyboardDatePicker
                            variant="inline"
                            format="DD/MM/YYYY"
                            margin="normal"
                            className="date-picker-class"
                            placeholder="Start Date"
                            value={this.state.startDate}
                            id="startDate"
                            onChange={(event) => this.handleStartDate(event)}
                            autoOk
                            fullWidth
                            error={false}
                            helperText={false}
                            inputVariant="outlined"
                            InputProps={{
                              readOnly: true
                            }}
                          />
                        </MuiPickersUtilsProvider>
                      </ThemeProvider>
                    </Grid>
                    <Grid item xs={12} sm={6} md={3}>
                      <ThemeProvider theme={theme}>
                        <MuiPickersUtilsProvider utils={MomentUtils}>
                          <KeyboardDatePicker
                            format="DD/MM/YYYY"
                            id="endDate"
                            className="date-picker-class"
                            fullWidth
                            placeholder="End Date"
                            autoOk
                            variant="inline"
                            minDate={this.state.startDate}
                            disabled={!this.state.startDate}
                            margin="normal"
                            value={this.state.endDate}
                            onChange={(event) => this.handleEndDate(event)}
                            error={false}
                            helperText={false}
                            inputVariant="outlined"
                            InputProps={{
                              readOnly: true
                            }}
                          />
                        </MuiPickersUtilsProvider>
                      </ThemeProvider>
                    </Grid>
                  </Grid>
                  <Box className="divider-row"></Box>
                  <Grid container spacing={2} className="dateRange-main-grid">
                    <Grid item xs={12}>
                      <Box className="textfield-main">
                        <Box className="w-full">
                          <TextareaAutosize
                            className="textarea-input"
                            id="advanceSearch"
                            rows={6} 
                            onChange={(event) => this.handleAdvanceSearch(event)}
                            value={this.state.advanceInput}
                            placeholder="Type here"
                            aria-label="maximum height"
                          />
                        </Box>
                      </Box>
                    </Grid>
                  
                  </Grid>
                  <Box className="divider-row" ></Box>
                  <Grid container spacing={2}>
                    <Grid item xs={12} sm={6} md={4}>
                      <Box className="textfield-main">
                      <Box className="w-full">
                            <TextField
                              className="addText-input"
                              placeholder="Inventor"
                              variant="outlined"
                              name="name"
                              id= "inventorId"
                              value={this.state.inputInventor}
                              onChange={(event) => this.handleInputInventor(event)}
                              fullWidth
                              inputProps={{
                                className: "textarea-placeholder"
                              }}
                              InputProps={{
                                startAdornment: (
                                  <InputAdornment position="start">
                                    <img src={avtarLogo} alt="assigneeLogo" />
                                  </InputAdornment>
                                ),
                              }}
                            />
                          </Box>
                        <Box>
                          <Box className="searchTermsImage-button" id= "plushIconId" onClick={this.addMoreInput}>
                            <img src={plusIcon} alt="plusIcon" />
                          </Box>
                        </Box>
                      </Box>
                     
                    {this.state.inputFields.map((inputField: {name: string}, index: number) => (
                        <Box className="textfield-main" style={{marginTop:"15px"}}>
                          <Box className="w-full">
                            <TextField
                              className="addText-input"
                              placeholder="Inventor"
                              variant="outlined"
                              name="name"
                              id = "addTextInputId"
                              value={inputField.name}
                              onChange={(event) => this.handleInputChange(index, event)}
                              fullWidth
                              inputProps={{
                                className: "textarea-placeholder"
                              }}
                              InputProps={{
                                startAdornment: (
                                  <InputAdornment position="start">
                                    <img src={avtarLogo} alt="assigneeLogo" />
                                  </InputAdornment>
                                ),
                              }}
                            />
                          </Box>
                          <Box>
                            <Box className="searchTermsImage-button" onClick={this.addMoreInput}>
                              <img src={plusIcon} alt="plusIcon" />
                            </Box>
                          </Box>
                        </Box>
                    ))}
                    </Grid>
                    <Grid item xs={12} sm={6} md={4}>
                      <Box className="textfield-main">
                        <Box className="w-full">
                          <TextField
                            className="addText-input"
                            placeholder="Assignee"
                            variant="outlined"
                            id="assigneeInput"
                            value={this.state.inputFieldsAssignee}
                            onChange={(event) => this.handleAssigneeInventor(event)}
                            name="name"
                            fullWidth
                            inputProps={{
                              className: "textarea-placeholder"
                            }}
                            InputProps={{
                              startAdornment: (
                                <InputAdornment position="start">
                                  <img src={assigneeLogo} alt="assigneeLogo" />
                                </InputAdornment>
                              ),
                            }}
                          />
                        </Box>
                        <Box>
                          <Box className="searchTermsImage-button">
                            <img src={plusIcon} onClick={this.handleaddMoreInput} id="assigneePlusBtn" alt="plusIcon" />
                          </Box>
                        </Box>
                      </Box>

                      {this.state.inputAssignee.map((inputField: any, index: number) => (
                         <Box className="textfield-main" style={{marginTop:"15px"}}>
                         <Box className="w-full">
                           <TextField
                             className="addText-input"
                             placeholder="Assignee"
                             variant="outlined"
                             name="name"
                             id="assigneeAddMore"
                             value={inputField.name}
                             onChange={(event) => this.handleAssigneeChange(index, event)}
                             fullWidth
                             inputProps={{
                               className: "textarea-placeholder"
                             }}
                             InputProps={{
                               startAdornment: (
                                 <InputAdornment position="start">
                                   <img src={assigneeLogo} alt="assigneeLogo" />
                                 </InputAdornment>
                               ),
                             }}
                           />
                         </Box>
                         <Box>
                           <Box className="searchTermsImage-button">
                             <img src={plusIcon} onClick={this.handleaddMoreInput} alt="plusIcon" />
                           </Box>
                         </Box>
                       </Box>
                    ))}
                    </Grid>
                    <Grid item xs={12} sm={6} md={4}>
                      <FormControl fullWidth>
                        <Select
                          className="select-input"
                          value={this.state.patentOffice}
                          onChange={(event) => this.handleChangePatentOffice(event.target.value as string[])}
                          input={<BootstrapInput />}
                          id="selectMenu1"
                          displayEmpty
                          placeholder="patentOffice"
                          IconComponent={(props) => <img {...props} src={dropdown} alt="dropdown" />}
                          multiple
                          renderValue={() => {
                            return <Box className={this.getPatentOfficeClassName()} >Patent Office  {this.getPatentOfficeDisplayValue() }</Box>;
                          }}
                          MenuProps={{
                            MenuListProps: {
                              style: { padding: 0 }
                            },
                            anchorOrigin: {
                              vertical: "bottom",
                              horizontal: "left"
                            },
                            transformOrigin: {
                              vertical: "top",
                              horizontal: "left"
                            },
                            getContentAnchorEl: null
                          }}
                        >
                          {this.state.patentOfcArr.map((item, index) => (
                            <StyledMenuItemLanguage className="dateRange-menu-style" key={index} value={item.name}>
                              <Checkbox checked={this.state.patentOffice.indexOf(item.name) > -1}
                                checkedIcon={
                                  <img
                                    src={checkedIcon}
                                    alt="barcodeIp"
                                  />
                                } icon={
                                  <img
                                    src={uncheckedIcon}
                                    alt="barcodeIp"
                                  />
                                }
                              />
                              <ListItemText primary={item.name} />
                            </StyledMenuItemLanguage>
                          ))}
                        </Select>
                      </FormControl>
                    </Grid>
                  </Grid>
                  <Box className="divider-row" ></Box>
                  <Grid container spacing={2} className="dateRange-main-grid">
                    <Grid item xs={12} sm={6} md={3}>
                      <FormControl fullWidth>
                        <Select
                          className="select-input"
                          value={this.state.selectedLanguage}
                          onChange={(event) => this.handleLanguageChange(event.target.value as string[])}
                          input={<BootstrapInput />}
                          id="selectMenu2"
                          displayEmpty
                          placeholder="Language"
                          IconComponent={(props) => <img {...props} src={dropdown} alt="dropdown" />}
                          multiple
                          renderValue={() => {
                            return <Box className={this.state.selectedLanguage?.length ? "selectedColor" : "notselectedColor"}>Language {this.state.selectedLanguage?.length !== 0 &&  `(${this.state.selectedLanguage?.length})` }</Box>;
                          }}
                          MenuProps={{
                            MenuListProps: {
                              style: { padding: 0 }
                            },
                            anchorOrigin: {
                              vertical: "bottom",
                              horizontal: "left"
                            },
                            transformOrigin: {
                              vertical: "top",
                              horizontal: "left"
                            },
                            getContentAnchorEl: null
                          }}
                        >
                          {this.state.languageArr.map((item, index) => (
                            <StyledMenuItemLanguage className="dateRange-menu-style" key={index} value={item.value}>
                              <Checkbox checked={this.state.selectedLanguage.indexOf(item.value) > -1}
                                checkedIcon={
                                  <img
                                    src={checkedIcon}
                                    alt="barcodeIp"
                                  />
                                } icon={
                                  <img
                                    src={uncheckedIcon}
                                    alt="barcodeIp"
                                  />
                                }
                              />
                              <ListItemText primary={item.name} />
                            </StyledMenuItemLanguage>
                          ))}
                        </Select>
                      </FormControl>
                    </Grid>
                    <Grid item xs={12} sm={6} md={3}>
                      <FormControl fullWidth>
                        <Select
                          className="select-input"
                          value={this.state.status}   
                          input={<BootstrapInput />}
                          id="selectMenu3"
                          displayEmpty
                          placeholder="Language"
                          IconComponent={(props) => <img {...props} src={dropdown} alt="dropdown" />}
                          renderValue={(selectedStatus) => (
                            this.handleRenderValue(selectedStatus as string, "Status")
                          )}
                          MenuProps={{
                            MenuListProps: {
                              style: { padding: 0 }
                            },
                            anchorOrigin: {
                              vertical: "bottom",
                              horizontal: "left"
                            },
                            transformOrigin: {
                              vertical: "top",
                              horizontal: "left"
                            },
                            getContentAnchorEl: null
                          }}
                        >
                          <StyledMenuItemLanguage className="dateRange-menu-style" value="GRANT">
                            <Checkbox
                              checked={this.state.status === "GRANT"}
                              checkedIcon={<img src={checkedIcon} alt="checked" />}
                              icon={<img src={uncheckedIcon} alt="unchecked" />}
                              value={"GRANT"}
                              id="StatusId"
                              onChange={(event) => this.handleStatusChange(event)}
                            />
                            <ListItemText primary="GRANT" />
                          </StyledMenuItemLanguage>
                          <StyledMenuItemLanguage className="dateRange-menu-style" value="APPLICATION">
                            <Checkbox
                              checked={this.state.status === "APPLICATION"}
                              checkedIcon={<img src={checkedIcon} alt="checked" />}
                              icon={<img src={uncheckedIcon} alt="unchecked" />}
                              value={"APPLICATION"}
                              onChange={(event) => this.handleStatusChange(event)}
                            />
                            <ListItemText primary="APPLICATION" />
                          </StyledMenuItemLanguage>
                        </Select>
                      </FormControl>
                    </Grid>
                    <Grid item xs={12} sm={6} md={3}>
                      <FormControl fullWidth>
                        <Select
                          className="select-input"
                          value={this.state.type}
                          input={<BootstrapInput />}
                          id="selectMenu4"
                          displayEmpty
                          renderValue={(selectedType) => (
                            this.handleRenderValue(selectedType as string, "Type")
                          )}
                          IconComponent={(props) => <img {...props} src={dropdown} alt="dropdown" />}
                          MenuProps={{
                            PaperProps: {
                              style: { marginTop: 4 }
                            },
                            MenuListProps: {
                              style: { padding: 4 }
                            },
                            anchorOrigin: {
                              vertical: "bottom",
                              horizontal: "left"
                            },
                            transformOrigin: {
                              vertical: "top",
                              horizontal: "left"
                            },
                            getContentAnchorEl: null
                          }}
                        >
                          <StyledMenuItemLanguage className="dateRange-menu-style" value="PATENT">
                            <Checkbox
                              checked={this.state.type === "PATENT"}
                              checkedIcon={<img src={checkedIcon} alt="checked" />}
                              icon={<img src={uncheckedIcon} alt="unchecked" />}
                              value={"PATENT"}
                              id="typeId"
                              onChange={(event) => this.handleChangeType(event)}
                            />
                            <ListItemText primary="PATENT" />
                          </StyledMenuItemLanguage>
                          <StyledMenuItemLanguage className="dateRange-menu-style" value="DESIGN">
                            <Checkbox
                              checked={this.state.type === "DESIGN"}
                              checkedIcon={<img src={checkedIcon} alt="checked" />}
                              icon={<img src={uncheckedIcon} alt="unchecked" />}
                              value={"DESIGN"}
                              onChange={(event) => this.handleChangeType(event)}
                            />
                            <ListItemText primary="DESIGN" />
                          </StyledMenuItemLanguage>
                        </Select>
                      </FormControl>
                    </Grid>
                    <Grid item xs={12} sm={6} md={3}>
                      <FormControl fullWidth>
                        <Select
                          className="select-input"
                          value={this.state.litigation}
                          displayEmpty
                          renderValue={(selected) => (
                            this.handleRenderValue(selected as string, "Litigation")
                          )}
                          input={<BootstrapInput />}
                          id="selectMenu5"
                          IconComponent={(props) => <img {...props} src={dropdown} alt="dropdown" />}
                          MenuProps={{
                            PaperProps: {
                              style: { marginTop: 4 }
                            },
                            MenuListProps: {
                              style: { padding: 4 }
                            },
                            anchorOrigin: {
                              vertical: "bottom",
                              horizontal: "left"
                            },
                            transformOrigin: {
                              vertical: "top",
                              horizontal: "left"
                            },
                            getContentAnchorEl: null
                          }}
                        >
                          <StyledMenuItemLanguage className="dateRange-menu-style" value="Has Related Litigation">
                            <Checkbox
                              checked={this.state.litigation === "Has Related Litigation"}
                              checkedIcon={<img src={checkedIcon} alt="checked" />}
                              icon={<img src={uncheckedIcon} alt="unchecked" />}
                              value={"Has Related Litigation"}
                              id="LitigationId"
                              onChange={(event) => this.handleChangeLitigation(event)}
                            />
                            <ListItemText primary="Has Related Litigation" />
                          </StyledMenuItemLanguage>
                          <StyledMenuItemLanguage className="dateRange-menu-style" value="No Known Litigation">
                            <Checkbox
                              checked={this.state.litigation === "No Known Litigation"}
                              checkedIcon={<img src={checkedIcon} alt="checked" />}
                              icon={<img src={uncheckedIcon} alt="unchecked" />}
                              value={"No Known Litigation"}
                              onChange={(event) => this.handleChangeLitigation(event)}
                            />
                            <ListItemText primary="No Known Litigation" />
                          </StyledMenuItemLanguage>
                        </Select>
                      </FormControl>
                    </Grid>
                  </Grid>
                </Box>
                <Box className="search-main-box">
                  <Grid item xs={12}>
                    <Box className="dateRange-contain2-text">What are you looking for</Box>
                  </Grid>
                  <Grid item xs={12} className="search-grid">
                    <Box className="search-box-looking">
                      {this.state.searchboxArr.map((item: { name: string }, index: number) => (
                        <Box className="box-search-loop">
                          <Box key={index} className="box-name">{item.name}</Box>
                        </Box>
                      ))}
                    </Box>
                  </Grid>
                  <Box className="divider-row"></Box>
                  <Grid item xs={12}>
                    <Box className="textfield-main">
                      <Box className="w-full">
                        <TextField
                          className="textFeild-input"
                          placeholder="Write a topic you want to search about"
                          variant="outlined"
                          name="name"
                          fullWidth
                          inputProps={{
                            className: "textFeild-placeholder"
                          }}
                        />
                      </Box>
                    </Box>
                  </Grid>
                  <Grid item xs={12} className="search-grid">
                    <Box className="topic-box-container">
                      {this.state.topicArr.map((item: { name: string }, index: number) => (
                        <Box className="topic-box-style">
                          <Box className="search-checkbox-main">
                            <Checkbox
                              checked={this.state.topicCheckbox.includes(item.name)}
                              value={item.name}
                              id="checkBoxTopic"
                              onChange={(event) => this.handleTopicCheckBoxChange(event)}
                              checkedIcon={
                                <img
                                  src={checkedIcon}
                                  alt="barcodeIp"
                                />
                              } icon={
                                <img
                                  src={uncheckedIcon}
                                  alt="barcodeIp"
                                />
                              } color="primary" className="checkBoxStyle" />
                            <Box key={index} className="box-name">{item.name}</Box>
                          </Box>
                        </Box>
                      ))}
                    </Box>
                  </Grid>
                </Box>
              </Box>
            </Grid>
          </Grid>
        </Box>
      ) : ("")}
    </Box>
  }

  historyMainloop = () => {
    return (
      <Box className="history-main-loop">
        {this.state.getAllRecentSearches?.data?.data ?
          this.state.getAllRecentSearches?.data?.data.map((item: any, index: number) => {
          const {  created_at, case_id, user_string } = item.attributes;
          return (
            <Box key={index}>
              {index !== 0 && <Box className="history-divider"></Box>}
              
              <Box className="history-Electronic-box">
                {this.state.showShimmer ? (
                  <Skeleton animation="wave" height={30} width="50%" />
                ) : (
                  <Link to={"/searches"} className='remove-underline' id={`profilesearch${index}`} onClick={()=>this.redirectToRecentSearch(case_id)}  >

                    <Box className="history-Electronic-Text" style={{ whiteSpace: 'nowrap', overflow: 'hidden',textOverflow: 'ellipsis',width: '30vw' }}>{user_string}</Box>                  
                  </Link>
                )}

                {this.state.showShimmer ? (
                  <Skeleton animation="wave" height={30} width="25%" />
                ) : (
                  <Box className="history-date-style">
                    {moment(created_at).format("DD MMM YYYY")}
                    <Link to={"/searches"} className='remove-underline' id={`profilesearchHistory${index}`} onClick={()=>this.redirectToRecentSearch(case_id)}  >
                    <img alt="rightArrow" src={rightArrow} />
                    </Link>
                  </Box>
                )}
  
              </Box>
  
              {this.state.showShimmer ? (
                <Skeleton animation="wave" height={30} width="30%" />
              ) : (
                <Box className="history-caseId">Case ID: {case_id}</Box>
              )}


            </Box>
          );
        }):
        <Typography className="classification-error">No Data Found</Typography> 
      
      
      }
      </Box>
    );
  };
  
  
  historySecoundLoop = () => {
    return (
      <Box className="history-main-loop">
        {this.state.getAllSupportAnalysts?.data ?
          this.state.getAllSupportAnalysts?.data.map((item: any, index: number) => {
          const {  date_of_submission, case_id, prompt,message } = item.attributes;
          return (
            <Box key={index}>
              {index !== 0 && <Box className="history-divider"></Box>}
              
              <Box className="history-Electronic-box">
                {this.state.showShimmer ? (
                  <Skeleton animation="wave" height={30} width="50%" />
                ) : (
                  <Link to={"/searches"} className='remove-underline' id={`analystsearch${index}`} onClick={()=>this.redirectToRecentSearch(case_id)}  >

                    <Box className="history-Electronic-Text" style={{ whiteSpace: 'nowrap', overflow: 'hidden',textOverflow: 'ellipsis',width: '30vw' }}>{prompt}</Box>
                  </Link>
                )}
                {this.state.showShimmer ? (
                  <Skeleton animation="wave" height={30} width="25%" />
                ) : (
                  <Box className="history-date-style">
                    {moment(date_of_submission).format("DD MMM YYYY")}
                    <Link to={"/searches"} className='remove-underline' id={`analystsearchHistory${index}`} onClick={()=>this.redirectToRecentSearch(case_id)}  >
                    <img alt="rightArrow" src={rightArrow} />
                    </Link>
                  </Box>
                )}
              </Box>
              {this.state.showShimmer ? (
                <Skeleton animation="wave" height={30} width="30%" />
              ) : (
                <Box className="history-caseId">Case ID: {case_id}</Box>
              )}
                {this.state.showShimmer ?
              <Skeleton height={20} animation="wave" width="100%" variant="text" />
              :
              <Box className="history-Text">{message}</Box>
            }
              
            </Box>
          );
        }) :
        <Typography className="classification-error">No Data Found</Typography> 
      }
      </Box>
    );
  };

  sliderSetting = () => {
    const newsetting={...settings,nextArrow:this.state.isDisabled ? null : <NextArrow />,prevArrow: this.state.isDisabled ? null : <PrevArrow />    }
    return <Slider {...newsetting} >
      {this.state.articleData?.map((item: ArticlesResponse, index: number) => {
        return (
          <div>
            <Box className="slider-main-box">
              <Box className="slider-Text">
                <Box className="slider-main">
                  <Box>
                    <img src={item?.attributes?.article_image?.url} alt="groupTop" className="card-Img" />
                    <Box>

                  <Box className="slider-text-main">
                  <Box className="card-title line-clamp-3">   
                      {item?.attributes?.title}
                    </Box>
                    <Box className="card-text line-clamp-4">
                      {item?.attributes?.description}
                    </Box>
                  </Box>
                  </Box>
                  </Box>
                  <Box className="card-date-main">
                    <Box className="card-date">
                      {moment(item?.attributes?.updated_at).format("MMMM DD, YYYY")}
                    </Box>
                    <Box 
                    onClick={() => this.handleModelOpen(item)}
                    id="test-id" 
                    className="card-viewMore"
                    // data-testid={`view-more-button-${index}`}
                    >
                      View more 
                      <img src={arrowIcon} alt="arrowIcon" />
                    </Box>                
                  </Box>
                </Box>
              </Box>
            </Box>
         
          </div>
          
        )
      })}
       <SuccessDialog
          open={this.state.openModel}
          id="onClose-model"
          onClose={this.handleModelClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
          PaperProps={{
            className: "delete-modal",
            elevation: 0,
          }}
          BackdropProps={{
            className: "backdrop-modal-delete",
          }}
        >
          <Box className="closeIconStyle" id="model-close" onClick={this.handleModelClose}>
            <IconButton aria-label="close">
              <img src={closeIcon} height={24} width={24} alt="closeIcon" />
            </IconButton>
          </Box>
          {this.state.selectedArticle && (
            <Box className="dialog-content">
              <Box className="">
                <img
                  src={this.state.selectedArticle?.attributes?.article_image?.url}
                  alt="Article"
                 className="modal-article-image"
                />
              </Box>
              <Box className="modal-title" >
                {this.state.selectedArticle?.attributes?.title}
              </Box>
              <Box className="modal-description">
                {this.state.selectedArticle?.attributes?.description}
              </Box>
              <Box className="modal-date">
                {moment(this.state.selectedArticle?.attributes?.updated_at).format(
                  "MMMM DD, YYYY"
                )}
              </Box>
            </Box>
          )}
        </SuccessDialog>
    </Slider>
  }
  loadingsliderSetting = () => {
    return <Slider {...settings} >
      {[1, 2, 3].map((item: number, index: number) => {
        return (
          <div>
            <Box className="slider-main-box">
              <Box className="slider-Text">
                <Box className="slider-main">
                  <Skeleton variant="rect" width="100%" height={200} />
                  <Box className="slider-text-main">
                    <Skeleton animation="wave" height={30} width="100%" className="skeletonStyle" />
                    <Skeleton animation="wave" height={30} width="90%" />

                    <Skeleton animation="wave" variant="text" height={20} width="100%" className="skeletonStyle" />
                    <Skeleton animation="wave" variant="text" height={20} width="90%" className="skeletonStyle" />
                    <Skeleton animation="wave" variant="text" height={20} width="70%" />

                  </Box>
                  <Box className="card-date-main">

                    <Skeleton animation="wave" height={30} width="35%" />
                    <Skeleton animation="wave" height={30} width="35%" />
                  </Box>
                </Box>
              </Box>
            </Box>
          </div>
        )
      })}
    </Slider>
  }
  // Customizable Area End

  render() {    
    return (
      <>
        <Loader loading={this.state.loading} message={this.state.loaderMessage} />
        <Header
          title={""}
          name={this.state.userName}
          photo={this.state.profilePhoto}
        />
        <LandingPageContainerCss>
          <Grid container>
            <Grid item xs={12}>
              <Box className="landingImgMainBox">
                <img
                  src={this.state.contactData?.attributes?.header_image?.url ? this.state.contactData?.attributes?.header_image?.url : placeHolderImage}
                  alt="landingBackImage"
                  className="backImageStyle"
                />
                <Box className="landingImgTextBox">
                  <Box className="landingImgMainText">
                    {this.state.contactData?.attributes?.welcome_note}
                  </Box>
                  <Box className="landingSubText">
                    Browse & Discover - All in one place!
                  </Box>
                </Box>
              </Box>
            </Grid>
          </Grid>
          {this.serachBoxMain()}
          {this.state.generateFeaturesData?.length !== 0 ? (
            <>
              <Box className="key-feature-Main-container">
                <Box className="key-feature-main-box">
                  <Box className="key-feature-text" id="key-Features">Key Features</Box>
                  <Box
                    className="key-li-style"
                    dangerouslySetInnerHTML={{ __html: this.state.generateFeaturesData.join('<br/>') }}
                  ></Box>
                </Box>
                <Box>
                  <button className={this.getButtonClassName()}
                   disabled={this.viewResults()} id="navigate-key-feature" onClick={() => this.goToKeyFeaturePage()}>
                    View Results
                  </button>
                </Box>
              </Box>
            </>
          ) : (
            <>
              <Box className="search-History-Main-container">
                <Grid container spacing={2}>
                  <Grid item xs={12} md={6}>
                    <Box className="history-main-box">
                      {this.state.showShimmer ?
                        <Skeleton animation="wave" height={30} width="40%" /> :
                        <Box className="history-box">
                          <img src={historyIcon} alt="historyIcon" />
                          <Box className="Search-History-text">
                            Search History
                          </Box>
                        </Box>}
                      {this.historyMainloop()}
                    </Box>
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <Box className="history-main-box">
                      {this.state.showShimmer ?
                        <Skeleton animation="wave" height={30} width="40%" /> :
                        <Box className="history-box">
                          <img src={joinIcon} alt="joinIcon" />
                          <Box className="Search-History-text">
                            Connected with an Analyst
                          </Box>
                        </Box>}
                      {this.historySecoundLoop()}
                    </Box>
                  </Grid>
                </Grid>
              </Box>
              {this.state.articleData?.length>0&&<Box className="card-History-Main-container">
                <Box className="history-margin">
                  {this.state.showShimmer ?
                    <Skeleton animation="wave" height={50} width="30%" /> :
                    <Box className="history-title">
                     <span style={{fontSize:"18px"}}> Our Recent Articles</span>
                    </Box>}
                  {this.state.showShimmer ?
                    <Skeleton animation="wave" height={40} width="40%" /> :
                    <Box className="history-head-text">
                     <span style={{fontSize:"16px"}}> Stay infromed with our latest insights</span>
                    </Box>}
                </Box>
                {!this.state.showShimmer && this.sliderSetting()}
                {this.state.showShimmer && this.loadingsliderSetting()}
              </Box>}
            </>
          )}
        </LandingPageContainerCss>
        <Footer />
      </>
    );
  }
}

// Customizable Area Start

const charts = {
  xaxisChart: {
    colors: '#DF4833',
    fontSize: '12px',
  },
  titleBody: {
    color: '#F0ABA1',
    fontSize: '12px',
    fontWeight: 'bold',
  },
  titleSecound: {
    fontSize: '24px',
    color: '#DF4833',
  },
  yaxisChart: {
    color: '#F0ABA1',
    fontSize: '12px',
    fontWeight: 'bold',
  },
  labelsCss: {
    colors: '#DF4833',
    fontSize: '12px',
  }
}
const UploadDocDialog = styled(Dialog)({
  "& .uploadDoc-modal": {
    height: 400,
    background: '#fff',
    borderRadius: 8,
    maxWidth: 607,
  },
  "& .backdrop-modal": {
    backgroundColor: 'rgba(15, 23, 42, 0.4)'
  },
  "& .browser-box": {
    display: 'flex',
    gap: 8
  },
  "& .box-upload": {
    padding: 45,
    height: "100%"
  },
  "& .title-model": {
    color: '#4F5356',
    fontFamily: 'Inter',
    fontSize: 16,
    fontWeight: 700,
    marginBottom: 5
  },
  "& .model-browser": {
    fontFamily: 'Inter',
    fontSize: 16,
    fontWeight: 700,
    color: "#DF4833"
  },
  "& .model-title-box": {
    display: "flex",
    flexDirection: "column",
    gap: 8
  },
  "& .model-Text": {
    color: '#727578',
    fontFamily: 'Inter',
    fontSize: 14,
    fontWeight: 400,
  },
  "& .uploadDoc-main-box": {
    display: "flex",
    alignItems: 'center',
    flexDirection: 'column',
    textAlign: "center",
    height: "100%",
    justifyContent: "center",
    gap: 16,
    border: "2px dashed #D3D3D3"
  },
  "& .closeIconStyle": {
    position: 'absolute',
    top: 7,
    right: 10,
  },
  "& .closeIconStyle-upload": {
    position: 'absolute',
    top: 20,
    right: 30,
  },
  "& .MuiDialogTitle-root": {
    padding: "24px 40px"
  },
  "& .uploadDoc-style": {
    cursor: 'pointer',
  },
  "& .uploadPhotoStyle": {
    display: "none"
  },
  "& .doc-box": {
    display: "flex",
    gap: 16,
    alignItems: 'center',
  },
  "& .doc-action": {
    padding: 30
  },
  "& .doc-img": {
    marginLeft: 70,
    cursor: "pointer"
  },
  "& .file-name": {
    fontFamily: "Inter",
    fontSize: 16,
    fontWeight: 400,
    color: "#0F172A",
    marginBottom: 5
  },
  "& .file-Time": {
    fontFamily: "Inter",
    fontSize: 16,
    fontWeight: 400,
    color: "#64748B"
  },
  "& .doc-img-box": {
    height: 36,
    width: 36,
    borderRadius: 8,
    padding: 16,
    background: '#FCEDEB',
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  "& .uploadBtn": {
    color: "#FFF",
    fontFamily: "Inter",
    fontSize: "16px",
    fontStyle: "normal",
    fontWeight: 700,
    borderRadius: "8px",
    background: "#EF4444",
    textTransform: "none",
    width: "160px",
    height: "56px",
    padding: "10px 16px",
    gap: "8px",
    cursor: "pointer",
    border: 'none',
  },
  "& .uploadDoc-main-box-inner": {
    background: "#FFFFFF",
    boxShadow: "0px 5px 23px -9px #0000004f",
    height: 72,
    width: 327,
    borderRadius: 8,
    display: "flex",
    padding: "8px 4px 8px 8px",
    gap: 16
  },
  "& .cancelBtn": {
    width: "160px",
    height: "56px",
    color: "#DF4833",
    fontFamily: "Inter",
    fontSize: "16px",
    fontStyle: "normal",
    fontWeight: 700,
    borderRadius: "8px",
    border: "1px solid #DF4833",
    textTransform: "none",
    padding: "10px 16px",
    gap: "8px",
    cursor: "pointer",
    background: "transparent"
  },
  "& .MuiDialogContent-dividers": {
    padding: 40
  },
  "& .dailog-title-style": {
    "& .MuiTypography-h6": {
      fontFamily: "Inter",
      fontWeight: 700,
      fontSize: 24,
      color: "#000000"
    }
  },
  "& .progressStyle": {
    width: '65%',
    "& .MuiLinearProgress-barColorPrimary": {
      backgroundColor: "#DF4833"
    }
  }
})

const webStyle = {
  prev: { zIndex: 1 },
  next: { display: "block", right: "30px" }
}

const theme = createTheme({
  palette: {
    primary: {
      main: "#EF4444",
    },
  },
});

const StyledMenuItem = withStyles((theme) => ({
  root: {
    color: "#727578",
    fontFamily: "Inter",
    fontSize: "14px",
    fontWeight: 400,
    borderRadius: 4,
    "&.MuiListItem-root.Mui-selected": {
      backgroundColor: "#F5C6C0",
      color: "#DF4833",
    }
  },
}))(MenuItem);

const StyledMenuItemLanguage = withStyles((theme) => ({
  root: {
    color: "#727578",
    fontFamily: "Inter",
    fontSize: "14px",
    fontWeight: 400,
    "&.MuiListItem-root.Mui-selected:hover": {
      backgroundColor: "#FEF2F2",
      color: "#DF4833",
    },
    "&.MuiListItem-root.Mui-selected": {
      backgroundColor: "#FEF2F2",
      color: "#DF4833",
    },
  },
}))(MenuItem);

const BootstrapInput = withStyles((theme: Theme) =>
  createStyles({
    root: {
      'label + &': {
        marginTop: theme.spacing(3),
      },
      "& .MuiSelect-icon": {
        top: 10,
        right: 5,
        height: "24px",
        width: "24px"
      },
      "& .MuiSelect-select:focus": {
        backgroundColor: "white",
      }
    },
    input: {
      color: "#727578",
      borderRadius: 4,
      position: 'relative',
      border: '1px solid #CBD5E1',
      fontSize: 14,
      padding: '11px 26px 11px 12px',
      transition: theme.transitions.create(['border-color', 'box-shadow']),
      fontFamily: "Inter",
      background: "white",
      '&:focus': {
        borderRadius: 4,
        borderColor: '#727578',
      },
      '&:placeholder': {
        color: "#AEB0B1",
      },
    },
  }),
)(InputBase);

const IOSSwitch = withStyles((theme) => ({
  root: {
    padding: 0,
    width: 40,
    height: 23,
  },
  switchBase: {
    padding: 1,
    top: 1,
    left: 1,
    '&$checked': {
      transform: 'translateX(17px)',
      color: "#DF4833",
      '& + $track': {
        opacity: 1,
        border: 'none',
        backgroundColor: "#F1F5F9",
      },
    },
    "& .MuiSwitch-colorSecondary-50.Mui-checked + .MuiSwitch-track-54": {
      backgroundColor: '#DF4833 !important'
    },
    '&$focusVisible $thumb': {
      color: '#DF4833',
      border: '6px solid #F1F5F9',
    },
    '& #swith': {
      marginTop: "1px",
    },
    "& .MuiSwitch-root-45": {
      marginRight: '16px !important'
    },
  },
  thumb: {
    width: 19,
    height: 19,
  },
  track: {
    borderRadius: 29 / 2,
    border: `0px solid ${theme.palette.grey[400]}`,
    opacity: 1,
    transition: theme.transitions.create(['background-color', 'border']),
    backgroundColor: '#E2E8F0',
  },
  checked: {},
  focusVisible: {},
}))(Switch);

const LandingPageContainerCss = styled(Box)({
  "& .slick-track": {
    marginLeft: "0 !important",
    marginRight: "auto"
  },
  "& .slick-slide":{
    height: "unset !important"
  },
  "& .notselectedColor":{
    color: "#AEB0B1"
  },
  "& .selectedColor":{
    color: "#000000"
  },
  "& .landingImgTextBox": {
    position: "absolute",
    width: "100%",
    height: "100%",
    left: 0,
    top: 0,
    flexDirection: "column",
    display: 'flex',
    gap: 8,
    alignItems: "center",
    justifyContent: "center"
  },
  "& .uploadDoc-main-box-search": {
    position: "absolute",
    background: "#FFFFFF",
    boxShadow: "0px 5px 23px -9px #0000004f",
    height: 60,
    width: "70%",
    borderRadius: 8,
    display: "flex",
    padding: "8px 4px 8px 8px",
    margin: "8px 40px",
    top: 0
  },
  "& .doc-box-search": {
    display: "flex",
    gap: 16,
    alignItems: 'center',
  },
  "& .doc-action-search": {
    padding: 30
  },
  "& .doc-img-search": {
    position: "absolute",
    right: 30,
    cursor: "pointer"
  },
  "& .file-name-search": {
    fontFamily: "Inter",
    fontSize: 16,
    fontWeight: 400,
    color: "#0F172A",
    marginBottom: 5
  },
  "& .file-Time-search": {
    fontFamily: "Inter",
    fontSize: 16,
    fontWeight: 400,
    color: "#64748B"
  },
  "& .doc-img-box-search": {
    height: 36,
    width: 36,
    borderRadius: 8,
    padding: 16,
    background: '#FCEDEB',
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  "& .progressStyle": {
    width: '70%',
    position: "absolute",
    top: 4,
    left: 45,
    "& .MuiLinearProgress-barColorPrimary": {
      backgroundColor: "#DF4833"
    },
  },
  "& .landingImgMainText": {
    color: "#FFF",
    fontFamily: "Inter",
    fontSize: 30,
    fontWeight: 700,
    "@media(max-width:500px)": {
      fontSize: 25,
    },
    "@media(max-width:450px)": {
      fontSize: 22,
    },
    "@media(max-width:350px)": {
      fontSize: 18,
    },
  },
  "& .landingSubText": {
    color: "#FFF",
    fontFamily: "Inter",
    fontSize: 18,
    fontWeight: 400,
    "@media(max-width:450px)": {
      fontSize: 12,
    },
    "@media(max-width:350px)": {
      fontSize: 10,
    },
  },
  "& .slick-slider": {
    height:"auto"
  },
  "& .slick-next-custom": {
    top: -100,
    position: "absolute",
    cursor: "pointer",
    "@media(max-width:651px)": {
      top: -60
    },
  },
  "& .slick-prev-custom": {
    right: 88,
    top: -100,
    position: "absolute",
    cursor: "pointer",
    "@media(max-width:651px)": {
      top: -60
    },
  },
  "& .landingImgMainBox": {
    position: "relative",
  },
  "& .backImageStyle": {
    width: "100%",
    height: "auto",
    maxHeight: "55vh",
    objectFit: "cover",
    display: "block",
    overflow: "hidden",
    aspectRatio: "16 / 9",
  },
  "& .select-input": {
    background: "#fff",
    borderRadius: 4
  },
  "& .FormTextField": {
    backgroundColor: "white",
    borderRadius: "8px",
    "& .MuiOutlinedInput-notchedOutline": {
      borderRadius: "8px",
      top: -6
    },
    '& .Mui-focused fieldset': {
      borderColor: '#4F5356',
    },
    "& .MuiFormHelperText-root.Mui-error": {
      fontFamily: "Poppins",
      fontWeight: 500,
      background: "#E8E7E7",
      color: "#FF1938"
    },
    "& .MuiFormHelperText-contained": {
      margin: 0
    },
    "& .MuiOutlinedInput-multiline": {
      paddingRight: 0
    },
    "& .MuiOutlinedInput-inputAdornedStart": {
      paddingRight: "50px"
    }
  },
  "& .textarea-input": {
    backgroundColor: "white",
    border: "2px solid #CBD5E1",
    fontSize: 14,
    padding: "10px",
    fontWeight: 400,
    width: "100%",
    color: "#0F172A",
    borderRadius: "8px",
    fontFamily: "Inter",
    resize: "none",
    "& .MuiOutlinedInput-notchedOutline": {
      borderRadius: "4px",
      top: -6
    },
    "& .MuiOutlinedInput-root": {
      height: "111px",
    },
    '& .Mui-focused fieldset': {
      borderColor: '#4F5356',
    },
    "& .MuiFormHelperText-contained": {
      margin: 0
    },
    "& .MuiFormHelperText-root.Mui-error": {
      fontFamily: "Poppins",
      fontWeight: 500,
      background: "#E8E7E7",
      color: "#FF1938"
    },
    "& .MuiOutlinedInput-multiline": {
      paddingRight: 0
    }
  },
  "& .addText-input": {
    backgroundColor: "white",
    width: "100%",
    fontFamily: "Inter",
    fontSize: 14,
    color: "#0F172A",
    fontWeight: 400,
    borderRadius: "8px",
    '& .Mui-focused fieldset': {
      borderColor: '#4F5356',
    },
    "& .MuiOutlinedInput-notchedOutline": {
      borderRadius: "4px",
      top: -6
    },
    "& .MuiFormHelperText-root.Mui-error": {
      fontFamily: "Poppins",
      fontWeight: 500,
      background: "#E8E7E7",
      color: "#FF1938"
    },
    "& .MuiOutlinedInput-multiline": {
      paddingRight: 0
    },
    "& .MuiOutlinedInput-root": {
      height: "45px",
    },
    "& .MuiFormHelperText-contained": {
      margin: 0
    },
  },
  "& .textarea-input2": {
    backgroundColor: "white",
    borderRadius: "8px",
    width: "100%",
    color: "#0F172A",
    fontFamily: "Inter",
    fontSize: 14,
    fontWeight: 400,
    "& .MuiFormHelperText-root.Mui-error": {
      fontFamily: "Poppins",
      fontWeight: 500,
      background: "#E8E7E7",
      color: "#FF1938"
    },
    "& .MuiOutlinedInput-multiline": {
      paddingRight: 0
    },
    "& .MuiOutlinedInput-notchedOutline": {
      borderRadius: "4px",
      top: -6
    },
    '& .Mui-focused fieldset': {
      borderColor: '#4F5356',
    },
    "& .MuiFormHelperText-contained": {
      margin: 0
    },
    "& .MuiOutlinedInput-root": {
      height: "45px",
    },
  },
  "& .textarea-placeholder": {
    "&::placeholder": {
      opacity: 1,
      color: "#898C8E",
      fontFamily: "Inter",
      fontSize: "14px",
      fontWeight: 400,
    },
  },
  "& .FormTextField-placeholder": {
    "&::placeholder": {
      opacity: 1,
      color: "#898C8E",
      fontFamily: "Inter",
      fontSize: "20px",
      fontWeight: 400,
    },
  },
  "& .textFeild-input": {
    backgroundColor: "white",
    borderRadius: "4px",
    "& .MuiOutlinedInput-root": {
      height: 44,
    },
    "& .MuiOutlinedInput-notchedOutline": {
      borderRadius: "4px",
      top: -6
    },
    '& .Mui-focused fieldset': {
      borderColor: '#4F5356',
    },
    "& .MuiFormHelperText-root.Mui-error": {
      fontFamily: "Poppins",
      fontWeight: 500,
      background: "#E8E7E7",
      color: "#FF1938"
    },
    "& .MuiFormHelperText-contained": {
      margin: 0
    },
    "& .MuiOutlinedInput-multiline": {
      paddingRight: 0
    }
  },
  "& .textFeild-placeholder": {
    "&::placeholder": {
      opacity: 1,
      color: "#898C8E",
      fontFamily: "Inter",
      fontSize: "14px",
      fontWeight: 400,
    },
  },
  "& .searchBox-Main": {
    padding: "40px 60px 10px",
    background: "#D3D3D3",
    "@media(max-width:1280px)": {
      padding: "40px 40px",
    },
    "@media(max-width:960px)": {
      padding: "40px 20px",
    },
    "@media(max-width:600px)": {
      padding: "40px 20px",
    },
  },
  "& .uploadIconBtnStyle": {
    color: "#DF4833",
    fontFamily: "Inter",
    fontSize: "14px",
    fontWeight: 700,
    borderRadius: 8,
    background: "#F5C6C0",
    cursor: 'pointer',
    outline: "none",
    border: 'none',
    display: "flex",
    alignItems: "center",
    gap: "5px",
    padding: "7px 4px",
    marginRight:"10px"
  },
  "& .main-field": {
    position: "relative",
    "& .Icon-Button": {
      position: "absolute",
      bottom: 30,
      right: 10
    }
  },
  "& .searchIconStyle": {
    marginBottom: 36
  },
  "& .advance-search-text": {
    color: "#4F5356",
    fontFamily: "Poppins",
    fontSize: "16px",
    fontWeight: 400,
  },
  "& .box-name": {
    maxWidth: "100%",
    fontSize: 14,
    color: "#64748B",
    fontFamily: "Inter",
    fontWeight: 400,
  },
  "& .search-checkbox-main": {
    display: "flex",
    alignItems: "center",
    width: "max-content",
    height: 25
  },
  "& .search-grid": {
    margin: "24px 0"
  },
  "& .checkBoxStyle": {
    padding: 0,
    paddingRight: 8,
  },
  "& .search-box-container": {
    display: "flex",
    justifyContent: "space-between",
    gap: 10,
    "@media(max-width:1013px)": {
      flexWrap: "wrap",
      justifyContent: "flex-start",
    },
  },
  "& .advance-search": {
    display: 'flex',
    alignItems: 'center',
    margin: "18px 0",
    gap: 16,
  },
  "& .white-container": {
    background: "#F5F5F4",
    borderRadius: 8,
    padding: 16,
    marginBottom: "2rem"

  },
  "& .heading-advanceSearch": {
    color: "#4F5356",
    fontFamily: "Poppins",
    fontSize: "30px",
    fontWeight: 700,
    marginBottom: 32
  },
  "& .main-box": {
    background: "#F1F5F9",
    width: "100%",
    height: 44,
    display: 'flex',
    alignItems: 'center',
    padding: '4px 20px',
    borderRadius: 4,
    "@media(max-width:1013px)": {
      width: "auto",
    },
  },
  "& .start-new-searchBtn": {
    marginTop: "24px",
    color: "#FFF",
    fontFamily: "Inter",
    fontStyle: "normal",
    fontWeight: 700,
    width: 250,
    borderRadius: 8,
    fontSize: 16,
    background: "#EF4444",
    gap: 8,
    textTransform: "none",
    height: 56,
    padding: "10px 16px",
    cursor: "pointer",
    border: 'none',
  },
  "& .start-new-searchBtn-disable": {
    marginTop: "24px",
    color: "#FFF",
    fontFamily: "Inter",
    fontStyle: "normal",
    fontWeight: 700,
    width: 250,
    borderRadius: 8,
    fontSize: 16,
    background: "#EF4444",
    gap: 8,
    textTransform: "none",
    height: 56,
    padding: "10px 16px",
    border: 'none',
    pointerEvents: "none",
    cursor: "not-allowed",
    opacity: 0.5,
  },
  "& .searchBtn": {
    color: "#FFF",
    fontFamily: "Inter",
    fontStyle: "normal",
    fontWeight: 700,
    width: 250,
    borderRadius: 8,
    fontSize: 16,
    background: "#EF4444",
    gap: 8,
    textTransform: "none",
    height: 56,
    padding: "10px 16px",
    cursor: "pointer",
    border: 'none',
  },
  "& .searchBtnDisable": {
    color: "#FFF",
    fontFamily: "Inter",
    fontStyle: "normal",
    fontWeight: 700,
    width: 250,
    borderRadius: 8,
    fontSize: 16,
    background: "#EF4444",
    opacity: 0.5,
    gap: 8,
    textTransform: "none",
    height: 56,
    padding: "10px 16px",
    cursor: "pointer",
    border: 'none',
  },
  "& .dateRange-container": {
    display: "flex",
    flexDirection: "column",
    gap: 24,
    "@media(max-width:900px)": {
      flexDirection: "column",
    },
  },
  "& .dateRange-main-box": {
    background: "#E7E5E4",
    borderRadius: 8,
    padding: 20,
    width: "auto",
    "@media(max-width:900px)": {
      minWidth: "auto",
      width: "auto"
    },
  },
  "& .dateRange-main-grid": {
    alignItems: "center",
  },
  "& .divider-row": {
    width: "100%",
    height: "1px",
    background: "#AEB0B1",
    margin: "16px 0"
  },
  "& .textfield-main": {
    display: "flex",
    gap: 10,
  },
  "& .advanceInputError": {
    color: "red",
    fontFamily: "Inter",
    fontSize: "14px",
    fontWeight: 400,
    marginTop: "5px",
  },
  "& .image-button": {
    height: 32,
    width: 32,
    borderRadius: "4px",
    border: "1px solid #CBD5E1",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    cursor: "pointer",
    background: "white",
  },
  "& .searchTermsImage-button": {
    height: "95%",
    width: "40px",
    borderRadius: "4px",
    border: "1px solid #CBD5E1",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    cursor: "pointer",
    background: "white",
  },
  "& .w-full": {
    width: "100%",
  },
  "& .selectBoxWrap": {
    marginTop: "16px",
    marginBottom: "8px",
  },
  "& .dateRange-text": {
    color: "#4F5356",
    fontFamily: "Inter",
    fontSize: "14px",
    fontWeight: 500,
    textAlign: "center",
  },
  "& .dateRange-contain2-text": {
    color: "#4F5356",
    fontFamily: "Inter",
    fontSize: "14px",
    fontWeight: 500,
  },

  "& .note-text": {
    color: "#64748B",
    fontFamily: "Inter",
    fontSize: "16px",
    fontWeight: 400,
    marginBottom: 10
  },
  "& .search-main-box": {
    background: "#E7E5E4",
    width: "auto",
    padding: 20,
    borderRadius: 8,
    display:"none"
  },

  "& .date-picker-class": {
    "& .MuiInputBase-root": {
      background: "white",
      display: 'flex',
      flexDirection: "row-reverse",
      fontFamily: "Inter",
      fontSize: 14,
      padding: '13px 26px 12px 12px',
      gap: 10,
      color: "#727578"
    },
    "& .MuiButtonBase-root": {
      padding: 0
    },
    "& .MuiInputBase-input": {
      padding: 0
    }
  },
  "& .search-box-looking": {
    display: "flex",
    justifyContent: "space-between",
    gap: 10,
    "@media(max-width:550px)": {
      flexWrap: "wrap",
    },
  },
  "& .box-search-loop": {
    background: "#F1F5F9",
    width: "100%",
    display: 'flex',
    alignItems: 'center',
    padding: '10px 16px',
    borderRadius: 4,
  },
  "& .topic-box-container": {
    display: "flex",
    flexWrap: "wrap",
    gap: 10,
  },
  "& .topic-box-style": {
    background: "#F1F5F9",
    width: "fit-content",
    height: 44,
    display: 'flex',
    alignItems: 'center',
    padding: '4px 20px',
    borderRadius: 4,
  },
  "& .search-History-Main-container": {
    padding: "50px 60px 50px",
    background: "#E8E7E7",
    "@media(max-width:1280px)": {
      padding: "40px 40px",
    },
    "@media(max-width:960px)": {
      padding: "40px 20px",
    },
    "@media(max-width:600px)": {
      padding: "40px 20px",
    },
  },
  "& .Search-History-text": {
    color: "#DF4833",
    fontFamily: "Inter",
    fontSize: "20px",
    fontWeight: 700,
  },
  "& .history-main-box": {
    borderRadius: "8px",
    background: "#FFF",
    padding: '24px 8px 30px 20px',
  },
  "& .history-box": {
    display: "flex",
    alignItems: "center",
    gap: 8
  },
  "& .history-main-loop": {
    marginTop: 24,
    height: "415px",
    overflow: "auto"
  },
  "& .classification-error": {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    height:"300px"
  },
  "& .history-divider": {
    width: "100%",
    height: 1,
    background: '#AEB0B1',
    margin: "30px 0",
  },
  "& .history-Electronic-box": {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
  },
  "& .remove-underline": {
    textDecoration: "none"
},
  "& .history-Electronic-Text": {
    color: "#4F5356",
    fontFamily: "Inter",
    fontSize: "18px",
    fontWeight: 700,
  },
  "& .history-date-style": {
    display: "flex",
    alignItems: "center",
    color: "#4F5356",
    fontFamily: "Inter",
    fontSize: "16px",
    fontWeight: 400,
  },
  "& .history-caseId": {
    color: "#EA8476",
    fontFamily: "Inter",
    fontSize: "18px",
    fontWeight: 700,
  },
  "& .history-Text": {
    color: "#4F5356",
    fontFamily: "Inter",
    fontSize: "16px",
    fontWeight: 400,
    marginTop: 5,
  },
  "& .slider-main-box": {
    padding: "0px 10px",
    overflow: "hidden", 
  }, 
  "& .slider-Text": {
    borderRadius: "8px",
    background: "#FFF",
    boxShadow:
      "0px 4px 8px 0px rgba(0, 0, 0, 0.03), 0px 8px 32px 0px rgba(0, 0, 0, 0.06)",
    padding: "24px 20px",
  },
  "& .history-margin": {
    marginBottom: 39,
    "@media(max-width:651px)": {
      marginBottom: 70,
    },
  },
  "& .history-title": {
    color: "#4F5356",
    fontFamily: "Inter",
    fontSize: "31px",
    fontWeight: 700
  },
  "& .history-head-text": {
    color: "#4F5356",
    fontFamily: "Inter",
    fontSize: "24px",
    fontWeight: 400
  },
  "& .slider-main": {
    display: "flex",
    flexDirection: "column",
    gap: 24,
    justifyContent:"space-between",
    height:"auto"
  },
  "& .card-Img": {
    height: "217px", 
     width: "100%",
    objectFit: "contain", 
    maxWidth:"100%"
  },
  "& .line-clamp-4": {
    display: "-webkit-box",
    WebkitBoxOrient: "vertical",
    WebkitLineClamp: 4, 
    overflow: "hidden"
  },
  "& .line-clamp-3": {
    display: "-webkit-box",
    WebkitBoxOrient: "vertical",
    WebkitLineClamp: 3, 
    overflow: "hidden"
  },
  "& .slider-text-main": {
    display: "flex",
    flexDirection: "column",
    gap: 10,
    marginTop:"16px",
  },
"& .card-title": {
    color: "#212324",
    fontFamily: "Inter",
    fontSize: "20px",
    fontWeight: 700,
    display: "-webkit-box",
    WebkitLineClamp: 3, 
    WebkitBoxOrient: "vertical",
    overflow: "hidden",
    textOverflow: "ellipsis",
    whiteSpace: "normal",
},
"& .card-text": {
    color: "#4F5356",
    fontFamily: "Inter",
    fontSize: "16px",
    fontWeight: 400,
    display: "-webkit-box",
    WebkitLineClamp: 4, 
    WebkitBoxOrient: "vertical",
    overflow: "hidden",
    textOverflow: "ellipsis",
    whiteSpace: "normal",
    height: "80px",
},
  "& .card-date-main": {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between"
  },
  "& .card-date": {
    color: "#4F5356",
    fontFamily: "Inter",
    fontSize: "16px",
    fontWeight: 400
  },
  "& .card-viewMore": {
    display: "flex",
    cursor:"pointer",
    alignItems: "center",
    gap: 8,
    color: "#DF4833",
    fontFamily: "Inter",
    fontSize: "18px",
    fontWeight: 400,
    position: "relative", 
},

"& .card-viewMore::after": {
    content: '""',
    position: "absolute",
    bottom: -4,
    left: 0,
    width: "100%", 
    height: "2px", 
    backgroundColor: "#DF4833", 
    transition: "width 0.3s ease", 
},

"& .card-viewMore:hover::after": {
    width: "100%", 
},

  "& .card-History-Main-container": {
    padding: "40px 60px 50px",
    background: "#D3D3D3",
    marginBottom:"-2.8rem",
    "@media(max-width:1280px)": {
      padding: "40px 40px",
    },
    "@media(max-width:960px)": {
      padding: "40px 20px",
    },
    "@media(max-width:600px)": {
      padding: "40px 20px",
    },
  },
  "& .skeletonStyle": {
    marginBottom: "-10px"
  },
  "& .key-feature-Main-container": {
    padding: "80px 60px",
    background: "#E8E7E7",
    "@media(max-width:1280px)": {
      padding: "40px 40px",
    },
    "@media(max-width:960px)": {
      padding: "40px 20px",
    },
    "@media(max-width:600px)": {
      padding: "40px 20px",
    },
  },
  "& .key-feature-main-box": {
    background: "#fff",
    height: "auto",
    overflowY: "scroll",
    borderRadius: 8,
    padding: "24px 40px",
    marginTop:"-2.3rem"
  },
  "& .key-feature-text": {
    fontFamily: 'Inter',
    fontSize: 24,
    fontWeight: 700,
    color: "#000000",
    marginBottom: 24
  },
  "& .first-independent-text": {
    fontFamily: 'poppins',
    fontSize: 22,
    fontWeight: 500,
    color: "#212324"
  },
  "& .key-li-style": {
    fontFamily: 'poppins',
    fontSize: 20,
    fontWeight: 400,
    color: "#4F5356"
  },
  "& .view-Results-btn": {
    color: "#FFF",
    fontFamily: "Inter",
    fontStyle: "normal",
    fontWeight: 700,
    width: 185,
    borderRadius: 8,
    fontSize: 16,
    background: "#DF4833",
    gap: 8,
    textTransform: "none",
    height: 56,
    padding: "10px 16px",
    cursor: "pointer",
    border: 'none',
    margin: "20px 0",
    pointerEvents: "auto",
    opacity: 1,
  },
  "& .view-Results-btn-disable": {
    color: "#FFF",
    fontFamily: "Inter",
    fontStyle: "normal",
    fontWeight: 700,
    width: 185,
    borderRadius: 8,
    opacity: 0.5,
    fontSize: 16,
    background: "#DF4833",
    gap: 8,
    textTransform: "none",
    height: 56,
    padding: "10px 16px",
    border: 'none',
    margin: "20px 0",
    pointerEvents: "none",
    cursor: "not-allowed",
  },
  "& .chart-main-box": {
    background: "#fff",
    borderRadius: 8,
    padding: "24px 5px"
  },
  "& .advance-search-buttons": {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "baseline",
  },
  "& .clear-all-button": {
    color: "#FFF",
    fontFamily: "Inter",
    fontStyle: "normal",
    fontWeight: 700,
    fontSize: 16,
    borderRadius: 8,
    background: "#EF4444",
    gap: 8,
    textTransform: "none",
    padding: "10px 16px",
    cursor: "pointer",
    border: 'none',
  },
});
// Customizable Area End
