import { Box, styled } from '@material-ui/core';
import { getStorageData, removeStorageData } from '../../framework/src/Utilities';
import React, { Component } from 'react';
import TermsConditions from "../../blocks/termsconditions/src/TermsConditions.web";
import { Message } from "../../framework/src/Message";
import { IBlock } from "../../framework/src/IBlock";
import MessageEnum, {
  getName
} from "../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../framework/src/RunEngine";


interface MyComponentProps {
    // Add props here
    title?: string
}
interface HeaderImage {
    url: string;
  }
interface Attributes {
    id: number;
    welcome_note: string;
    email: string;
    contact: string;
    address: string;
    copyright:string;
    header_image: HeaderImage;
  }
interface DataResponse {
    id: string;
    type: string;
    attributes: Attributes;
  }
// Define the state interface
interface MyComponentState {
    // Add state here
    footerDetails: {
        contactEmail: string;
        contactPhone: string;
        contactAddress: string;
        copyright:string;
    }
    openTermAndConditionModel: boolean,
    modelType: string,
    termsDoc: string,
    termsAndCondition: boolean,
    formValues: {
        rememberMe: boolean | undefined;
        email: string;
        password: string;
        terms: boolean; 
      },
      formErrors: {
        email: string;
        password: string;
        terms: string;
      },
   
}
interface Contact {
    data: DataResponse[];
    errors: [{ token: string }];
  }

class Footer extends Component<MyComponentProps, MyComponentState> {
  subScribedMessages: string[];
    // Initialize state
    constructor(props: MyComponentProps) {
        super(props);
        this.receive = this.receive.bind(this);

        this.subScribedMessages = [
          getName(MessageEnum.RestAPIResponceDataMessage),
          getName(MessageEnum.SessionSaveMessage),
          getName(MessageEnum.SessionResponseMessage),
          getName(MessageEnum.RestAPIResponceMessage),
          getName(MessageEnum.NavigationPayLoadMessage),
          getName(MessageEnum.AdvancedSearchDetails),
          getName(MessageEnum.PatentDetails)
        ];
        
        this.state = {
            // Add state here
            footerDetails: {
                contactEmail: "",
                contactPhone: "",
                contactAddress: "",
                copyright:"",
            },
            openTermAndConditionModel: false,
            modelType: "",
            termsDoc: "",
            termsAndCondition: false,
            formValues: {
                email: "",
                password: "",
                terms: false,
                rememberMe: undefined
              },
              formErrors: {
                email: "",
                password: "",
                terms: ""
              },
        };
        runEngine.attachBuildingBlock(this as unknown as IBlock, this.subScribedMessages);
    }
    apiContectDetailsCallId: string = "";

   async receive(from: string, message: Message) {
        const apiRequestCallId = message.getData(
          getName(MessageEnum.RestAPIResponceDataMessage)
        );
        const responseJson = message.getData(
          getName(MessageEnum.RestAPIResponceSuccessMessage)
        );      
        this.contactDetailsReceiveFunc(apiRequestCallId, responseJson);
      }
      
    
    async componentDidMount() {      
         this.contectDetailsAPI();    
    }

    contectDetailsAPI = async () => {
        const header = {
          token: await getStorageData("token"),
        };
    
        const requestMessage = new Message(
          getName(MessageEnum.RestAPIRequestMessage)
        );
    
        this.apiContectDetailsCallId = requestMessage.messageId;
    
        requestMessage.addData(
          getName(MessageEnum.RestAPIResponceEndPointMessage),
          "bx_block_landingpage2/landing_pages"
        );
    
        requestMessage.addData(
          getName(MessageEnum.RestAPIRequestHeaderMessage),
          JSON.stringify(header)
        );
    
        requestMessage.addData(
          getName(MessageEnum.RestAPIRequestMethodMessage),
           "GET"
        );
    
        runEngine.sendMessage(requestMessage.id, requestMessage);
      };

      handleErrorRes = (errorRes: { token: string }[]) => {
        if (errorRes[0].token) {
          this.goToLogInPage();
        }
      };
      goToLogInPage = async () => {
        removeStorageData("token");
        const message: Message = new Message(
          getName(MessageEnum.NavigationMessage)
        );
        message.addData(
          getName(MessageEnum.NavigationTargetMessage),
          "EmailAccountLoginBlock"
        );
        message.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
        const raiseMessage: Message = new Message(
          getName(MessageEnum.NavigationPayLoadMessage)
        );
        message.addData(getName(MessageEnum.NavigationRaiseMessage), raiseMessage);
      };
    
    contactDetailsReceiveFunc = (
        apiRequestCallId: string,
        responseJson: Contact
      ) => {
        if (apiRequestCallId === this.apiContectDetailsCallId) {
          if (responseJson?.errors?.length) {
            this.handleErrorRes(responseJson.errors);
          }
          if (responseJson?.data) {         
            const getCopyright = (responseJson:any) => {
              if (responseJson && responseJson.data && responseJson.data[0] && responseJson.data[0].attributes) {
                return responseJson.data[0].attributes.copyright;
              }
            };
            this.setState({
                footerDetails : {
                    contactEmail: responseJson?.data?.[0]?.attributes?.email,
                    contactPhone: responseJson?.data?.[0]?.attributes?.contact,
                    contactAddress: responseJson?.data?.[0]?.attributes?.address,
                    copyright:(getCopyright(responseJson))
                  }
            })
         
          }
        }
      };

    validateTerms = (check: boolean) => {
        if (!check) {
          return 'Please accept Privacy Policy and Terms & Conditions to proceed';
        } else {
          return '';
        }
      };
    
    hendleOpenTAndCModel = (type: string) => {
        if(this.state.termsDoc && type === "Terms") {
          const link = document.createElement('a');
        link.href = this.state.termsDoc;
        link.target = "_blank";
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        window.URL.revokeObjectURL(this.state.termsDoc);
        } else {
          this.setState({
            openTermAndConditionModel: true,
            modelType: type
          });
        }
      };
      handleChangeTerms = (event:any) => {
        const { checked } = event.target;
        
          this.setState((prevState) => ({
       
            termsAndCondition: !this.state.termsAndCondition,
            formValues: {
              ...prevState.formValues,
              terms: checked
            },
            formErrors: {
              ...prevState.formErrors,
              terms: this.validateTerms(checked)
            }
          }));
      };
      getTermDoc = (document: string) => {
        this.setState({
          termsDoc: document
        })
      }
      
      handleCloseTAndCModel = () => {
        this.setState({ openTermAndConditionModel: false })
      };
      

    render() {
        return (
            <FooterCss>
                <Box className='footerFirstContainer'>
                    <Box className='footer-main-box'>
                        <Box className='footer-logo-box'>
                            <img className='footerLogoStyle' src={require("./headerLogo.svg")} alt='headerLogo' />
                        </Box>
                        <Box className='footer-containt-box'>
                            <Box className='footer-heading-Text'>
                                Contact us
                            </Box>
                            <Box className='footer-text'>
                                {this.state.footerDetails?.contactPhone}
                            </Box>
                        </Box>
                        <Box className='footer-containt-box'>
                            <Box className='footer-heading-Text'>
                                Email
                            </Box>
                            <Box className='footer-text'>
                                {this.state.footerDetails?.contactEmail}
                            </Box>
                        </Box>
                        <Box className='footer-containt-box'>
                            <Box className='footer-heading-Text'>
                                Address
                            </Box>
                            <Box className='footer-text'>
                                {this.state.footerDetails?.contactAddress}
                            </Box>
                        </Box>
                    </Box>
                    <Box className='footer-divider'></Box>
                    <Box className='footer-copyRight-box'>
                        <Box className='footer-copy-text'>© {this.state.footerDetails?.copyright}</Box>
                        <Box  component="span" className='footer-privacy-text LogInCreateAcc-dif'
                         id="privacy-model-btn" 
                         onClick={this.hendleOpenTAndCModel.bind(this,"Privacy")}>Privacy</Box>
                    </Box>
                </Box>
                <TermsConditions
                  navigation={undefined}
                  id={"TermsConditions"}
                  getTermDoc={this.getTermDoc}
                  openTermAndConditionModel={this.state.openTermAndConditionModel}
                  modelType={this.state.modelType}
                  formValTerms={this.state.termsAndCondition}
                  handleChangeTerms={this.handleChangeTerms}
                  hendleOpenTAndCModel={this.hendleOpenTAndCModel}
                  handleCloseTAndCModel={this.handleCloseTAndCModel}
                />
            </FooterCss>
        );
    }
}

const FooterCss = styled(Box)({
    marginTop: 'auto',
    '& .footerFirstContainer': {
        background: '#E8E7E7',
        padding: '20px 60px 20px',
        "@media(max-width:1280px)": {
            padding: "40px 40px",
        },
        "@media(max-width:960px)": {
            padding: "40px 20px",
        },
        "@media(max-width:600px)": {
            padding: "40px 20px",
        },
    },
    "& .footer-main-box": {
        display: 'flex',
        justifyContent: "space-between",
        "@media(max-width:900px)": {
            flexDirection: "column",
            alignItems: "center",
            textAlign: "center",
            gap: "20px",
        },
    },
    "& .footer-logo-box": {
        display: "flex",
        alignItems: "center"
    },
    "& .footerLogoStyle": {
        display: "flex",
        width: "200px",
        height: "29px",
        padding: "0.271px 5px",
        justifyContent: "center",
        alignItems: "center",
        cursor: "pointer"
    },
    "& .footer-heading-Text": {
        color: "#4F5356;",
        fontFamily: "Inter",
        fontSize: "14px",
        fontWeight: 400,
        textTransform: "uppercase",
        marginBottom: 8
    },
    "& .footer-text": {
        color: "#484C4E",
        fontFamily: "Inter",
        fontSize: "18px",
        fontWeight: 700
    },
    "& .br-tag": {
        marginBottom: 12
    },
    "& .footer-divider": {
        width: "100%",
        height: 1,
        background: "#94A3B8",
        margin: "9px 0 24px 0"
    },
    "& .footer-copyRight-box": {
        display: "flex",
        justifyContent: "space-between"
    },
    "& .footer-copy-text": {
        color: "#212324",
        fontFamily: "Inter",
        fontSize: "12px",
        fontWeight: 400
    },
    "& .footer-privacy-text": {
        color: "#DF4833",
        fontFamily: "Inter",
        fontSize: "12px",
        fontWeight: 400
    },
})
export default Footer;