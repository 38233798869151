import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { getStorageData, removeStorageData, setStorageData } from "../../../framework/src/Utilities";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import ReactQuill from 'react-quill';
import { createRef, RefObject } from "react";

export interface Section {
  title: string;
  subtitle: string;
  index: number;
  content: string;
  prompt:string
  undoStack: { index: number; content: string }[]; 
  redoStack: { index: number; content: string }[];
}
// Define the interface for the entire API response
const sectionsData: Section[] = [
  {
    title: "Title of the invention",
    subtitle: "What is the title for your invention?",
    index: 1,
    content: '',
    prompt:'',undoStack: [], redoStack: []
  },
  {
    title: "Abstract of the invention",
    subtitle: "What is the Abstract for your invention?",
    index: 2,
    content: '',
    prompt:'',undoStack: [], redoStack: []

  },
  {
    title: "Background of the invention",
    subtitle: "What is the Background for your invention?",
    index: 3,
    prompt:'',
    content: '',undoStack: [], redoStack: []
  },
  {
    title: "Objective of the invention",
    subtitle: "What is the Objective for your invention?",
    index: 4,
    content: '',
    prompt:'',undoStack: [], redoStack: []
  },
  {
    title: "Summary of the invention",
    subtitle: "What is the Summary for your invention?",
    index: 5,
    content: '',
    prompt:'',undoStack: [], redoStack: []
  },
];
export interface SpecificationContent {
  title: string;
  content: string;
}



interface Specification {
  content: string | string[];
}

export interface ApiResponse {
  Specification: Specification;
  id:number;
}
export interface InventionContent {
  title: string;
  content: string;
}
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  // Customizable Area Start
  navigation: any;
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  sections:Section[]
  loading:boolean
  abstract:string
  errors:boolean
  selection:string
  promptindexcall:number
  disableprompt:boolean
  prrovisonalid:number|null
  disablerewrite:boolean
  rewriteindexcall:number
  undoStack: { index: number; content: string }[][];
  redoStack: { index: number; content: string }[][];
  // Customizable Area End
}

interface SS {
  id: any;
}

export default class ProvisionalSpecificationPageController extends BlockComponent<
  Props,
  S,
  SS
> {
  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [ 
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.NavigationPayLoadMessage),
      getName(MessageEnum.RestAPIResponceDataMessage),
      getName(MessageEnum.SessionSaveMessage),
      getName(MessageEnum.SessionResponseMessage),
      getName(MessageEnum.RestAPIResponceMessage),
    ];

    this.state = {
      errors:false,
      sections:sectionsData,
      loading:true,
      abstract:'',
      selection:'',
      promptindexcall:0,
      disableprompt:false,
      disablerewrite:false,
      prrovisonalid:0,
      rewriteindexcall:0,
      redoStack:[],
      undoStack:[],
    };
    // Customizable Area End
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    runEngine.debugLog("Message Recived", message);
    const apiRequestCallId = message.getData(
      getName(MessageEnum.RestAPIResponceDataMessage)
    );

    const responseJson = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );
    const tokenExpire = responseJson.errors?.[0]?.token
    if (tokenExpire) {
      this.goToLogInPage()
    }
    else if (apiRequestCallId === this.apiProvisonalDetailsAPIEndPoint) {
      if (responseJson.errors?.length) {
        this.setState({
          errors: true,loading: false
        }, () => {
          setStorageData("BackTrue", "BackTrue")
        })
      }
      else if (responseJson.Specification) {
        const sectionsData = this.mapApiResponseToSections(responseJson);
        sectionsData.forEach((section) => {
          this.quillRefs[`quillRef${section.index}`] = createRef();
        });
        this.setState({ sections: sectionsData, prrovisonalid: responseJson.id ,loading: false})
        setStorageData("prrovisonalid", responseJson.id)
      }
      else{
        this.setState({errors: true})
        this.setState({ loading: false })
      }
      setStorageData("BackTrue", "BackTrue")
    }
    else if (apiRequestCallId === this.apipromptCallID) {
      this.updateSectionContent(responseJson)
    }
    else if (apiRequestCallId === this.apiSaveProvsonalDetailsCallID) {
    if(!responseJson.error)
      this.setState({
        loading: false
      })
    }
    else if (apiRequestCallId === this.apiRewriteCallID) {
      this.updateContentBasedOnResponse(responseJson)
    }
    else  if (apiRequestCallId === this.apiProvisionalSpecificationsAPIEndPoint) {
      const sectionsData = this.mapApiResponseToSection(responseJson);
      sectionsData.forEach((section) => {
        this.quillRefs[`quillRef${section.index}`] = createRef();
      });
      this.setState({ sections: sectionsData, loading: false, errors: false })
    }
    // Customizable Area End
  }

  // Customizable Area Start
  apiProvisionalSpecificationsAPIEndPoint:string=""
  apiProvisonalDetailsAPIEndPoint:string=""
  apipromptCallID:string=""
  apiRewriteCallID:string=""
  apiSaveProvsonalDetailsCallID:string=""
  quillRefs : { [key: string]: RefObject<ReactQuill> } = {};
  private intervalId?: NodeJS.Timeout;

  async componentDidMount() {
    const loginToken = await getStorageData("token")
    if(!loginToken){
      this.goToLogInPage()
    }    

    const serachAgain = await getStorageData("advanchData")
    let id = await getStorageData("prrovisonalid")

    this.setState({
    abstract: serachAgain,
    prrovisonalid: id,
  });
  if (id) {
    await this.getPatentDetailsAPI();
  } else {
    await this.getProvisionalDetailsAPI();
  }
  this.intervalId = setInterval(this.handelSaved, 5000);
  };

  
  updateSectionContent = (responseJson:any) => {
    const response1Text = responseJson.Specification.join(""); 
    const indexcall = this.state.promptindexcall;
  
    this.setState(prevState => ({
      sections: prevState.sections.map((section, index) => ({
        ...section,
        prompt: index === indexcall ? response1Text : section.prompt
      }))
    }), () => {
      this.setState({ disableprompt: false });
      this.setState({ loading: false });
    });
  }
  
  updateContentBasedOnResponse = (responseJson:any) => {
    if (responseJson.errors) {
      this.setState({ errors: true });
    }
    else if(responseJson.status===500)
    {
        this.setState({ errors: true });
    } else {
      const indexcall = this.state.rewriteindexcall;
      this.setState(prevState => ({
        sections: prevState.sections.map((section, index) => ({
          ...section,
          content: index === indexcall
          ? responseJson.Specification.join(' ') 
            : section.content
        }))
      }), () => {
        this.setState({ disablerewrite: false });
      });
    }
  }
  
  async componentWillUnmount() {
    if (this.intervalId) clearInterval(this.intervalId);
  }
  
  handleMouseUp = (): void => {
    const selection = window.getSelection();
    if (selection && selection.rangeCount > 0) {
      const selectedText = selection.toString().trim();
      if (selectedText) {
        this.setState({ selection: selectedText });
      }
    }
  };
  
  goToLogInPage = async () => {
    removeStorageData("token");
    const message: Message = new Message(
      getName(MessageEnum.NavigationMessage)
    );
    message.addData(
      getName(MessageEnum.NavigationTargetMessage),
      "EmailAccountLoginBlock"
    );
    message.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    const raiseMessage: Message = new Message(
      getName(MessageEnum.NavigationPayLoadMessage)
    );
    message.addData(getName(MessageEnum.NavigationRaiseMessage), raiseMessage);
    this.send(message);
  };

  mapApiResponseToSections = (apiResponse: ApiResponse): Section[] => {
    // First time GPt PRovisonal API
    const getContentString = (content: string | string[]): string => {
      let contentString;
      if (typeof content === "string") {
        contentString = content;
      } else if (Array.isArray(content)) {
        contentString = content.join(" ");
      } else {
        contentString = "";
      }
      return contentString;
    }
    
      const contentArray = Object.entries(apiResponse.Specification).map(([key, value]) => ({
        title: key,
        content: getContentString(value.content)
      }));    
      return [
        {
          title: contentArray[0].title,
          subtitle: "What is the title for your invention?",
          index: 0,
          content: contentArray[0].content,
          prompt:'',undoStack: [], redoStack: []
        },
        {
          title:  contentArray[1].title,
          subtitle: "What is the Background for your invention?",
          index: 1,
          content: contentArray[1].content,
          prompt:'',undoStack: [], redoStack: []
        },
        {
          title: contentArray[2].title,
          subtitle: "What is the Summary for your invention?",
          index: 2,
          content: contentArray[2].content,
          prompt:'',undoStack: [], redoStack: []
        },
        {
          title:  contentArray[3].title,
          subtitle: "What is the Objective for your invention?",
          index: 3,
          content: contentArray[3].content, 
          prompt:'',undoStack: [], redoStack: []
        },
      
      ]; 
  };


  mapApiResponseToSection = (apiResponse: any): Section[] => {    
//Refresh Api
      return [
        {
          title: apiResponse.document.field_of_invention,
          subtitle: "What is the title for your invention?",
          index: 0,
          content: apiResponse.document.field_of_invention_specification,
          prompt:'',undoStack: [], redoStack: []
        },
        {
          title:  apiResponse.document.background_of_invention,
          subtitle: "What is the Background for your invention?",
          index: 1,
          content: apiResponse.document.background_of_invention_specification,
          prompt:'',undoStack: [], redoStack: []
        },
        {
          title: apiResponse.document.summary_of_invention,
          subtitle: "What is the Summary for your invention?",
          index: 2,
          content: apiResponse.document.summary_of_invention_specification,
          prompt:'',undoStack: [], redoStack: []
        },
        {
          title:  apiResponse.document.detailed_description_of_invention,
          subtitle: "What is the Objective for your invention?",
          index: 3,
          content: apiResponse.document.detailed_description_of_invention_specification, 
          prompt:'',undoStack: [], redoStack: []
        },
      ]; 
  };


  getPatentDetailsAPI = async () => {

    this.setState({ loading: true });
    const header = {
      token: await getStorageData("token"),
    };

    const prrovisonalid = this.state.prrovisonalid;
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.apiProvisionalSpecificationsAPIEndPoint = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `bx_block_landingpage2/provisional_specifications/${prrovisonalid}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  handlePromptChange = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>, targetIndex: number) => {
    const { value } = event.target; 
    
    this.setState(prevState => ({
      sections: prevState.sections.map((section, index) => ({
        ...section,
        prompt: index === targetIndex ? value : section.prompt // Update content for a specific section
      }))
    }));
    this.setState({promptindexcall:targetIndex});
  };

  Replace=async (targetIndex:number)=>
  {
    
    const targetSection = this.state.sections[targetIndex]
    const quillRef = this.quillRefs[`quillRef${targetSection.index}`]?.current;
    if (quillRef) {
      const editor = quillRef.getEditor(); 
      const selection = editor.getSelection(); 

      if (selection && selection.length > 0) {
        editor.deleteText(selection.index, selection.length);
        editor.insertText(selection.index, targetSection.prompt); 
        const updatedContent = editor.root.innerHTML;

        this.setState(prevState => ({
          sections: prevState.sections.map((section, index) => ({
            ...section,
            content:index===targetIndex ? updatedContent : section.content,
            prompt: index === targetIndex ? "" : section.prompt 
          }))
        }));
        this.setState({promptindexcall:targetIndex});
      } 
    }
  }

  Rewrite=async(index:number)=>{
    this.setState({disablerewrite:true})
    this.setState({rewriteindexcall:index})
    const abstract=this.state.sections[index].content;
    const section=this .state.sections[index].title 
    const header = {
      "Content-Type": configJSON.validationApiContentType,
      token: await getStorageData("token"),
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.apiRewriteCallID = requestMessage.messageId;
    
    const params = new URLSearchParams({
      type:"provisional",
      abstract: abstract,
      section:section
    }).toString();
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `bx_block_landingpage2/provisional_specifications/rewrite_specification?${params}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);

  }

  promptApiCall=async (index:number)=>
  {
    this.setState({disableprompt:true})
    const selection=this.state.selection;
    const prompt=this.state.sections[index].prompt;
    const type=this .state.sections[index].title // yha title hona chaye tha iseu ahi dekhna hai
    const header = {
      "Content-Type": configJSON.validationApiContentType,
      token: await getStorageData("token"),
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.apipromptCallID = requestMessage.messageId;
    
    const params = new URLSearchParams({
      type:type,
      abstract: selection,
      prompt: prompt,
    }).toString();

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.apipromptEndPoint}?${params}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);

  }

  getProvisionalDetailsAPI = async () => {
    const header = {
      token: await getStorageData("token"),
    };
    let promptId = await getStorageData("promptId")
    const abstract=this.state.abstract
    const specification_type='provisional'
    const params = new URLSearchParams({
      abstract: abstract,
      specification_type: specification_type,
      prompt_id:promptId
    }).toString();

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.apiProvisonalDetailsAPIEndPoint = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `bx_block_landingpage2/provisional_specifications/generate_provisional_specification?${params}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };
  
  goingToSpecificationPageReport = async () => {
    this.setState({loading:true})
    await this.handelSave();    //Waitng for save
    const message = new Message(getName(MessageEnum.NavigationMessage));
    message.addData(getName(MessageEnum.NavigationTargetMessage), "SpecificationPage");
    message.addData(getName(MessageEnum.NavigationPropsMessage), this.props); 
    this.send(message);
};

  handleContentChange(value: string, targetIndex: number) {
    this.setState(prevState => {
      const updatedSections = prevState.sections.map((section, index) => {
        if (index === targetIndex) {
          // Update specific section content and manage undo/redo stacks
          return {
            ...section,
            content: value,
            undoStack: [
              ...section.undoStack,
              { index: targetIndex, content: section.content }
            ],
            redoStack: [] // Clear redo stack on new change
          };
        }
        return section;
      });

      return { sections: updatedSections };
    });
  }

  handleUndo(index: number) {
    this.setState(prevState => {
      const section = prevState.sections[index];
      if (section.undoStack.length === 0) return null; // No action to undo

      const lastState = section.undoStack.pop(); // Get the last state from undo stack
      if (!lastState) return null; // If no last state, exit early

      const previousContent = lastState.content;

      // Push the current state to the redo stack
      const newRedoStack = [
        ...section.redoStack,
        { index, content: section.content }
      ];

      const updatedSections = prevState.sections.map((sec, idx) => ({
        ...sec,
        content: idx === index ? previousContent : sec.content,
        undoStack: idx === index ? section.undoStack : sec.undoStack,
        redoStack:newRedoStack
      }));

      return {
        sections: updatedSections
      };
    });
  }

  handleRedo(index: number) {
    this.setState(prevState => {
      const section = prevState.sections[index];
      if (section.redoStack.length === 0) return null; // No action to redo

      const nextState = section.redoStack.pop(); // Get the last state from redo stack
      if (!nextState) return null; // If no next state, exit early

      const nextContent = nextState.content;

      // Push the current state to the undo stack
      const newUndoStack = [
        ...section.undoStack,
        { index, content: section.content }
      ];

      const updatedSections = prevState.sections.map((sec, idx) => ({
        ...sec,
        content: idx === index ? nextContent : sec.content,
        undoStack: newUndoStack,
        redoStack: idx === index ? section.redoStack : sec.redoStack
      }));

      return {
        sections: updatedSections
      };
    });
  }

  handelSaved = () => {
    const id = this.state.prrovisonalid;
    if (id != null)
      this.handelSave();
  }
  handelSave = async () => {

    const header = {
      token: await getStorageData("token"),
    };

const formData = new FormData();

let id = await getStorageData("prrovisonalid")
setStorageData("Specid",id )

formData.append("id",id);
let type=this.state.sections[0].title 
let content=this.state.sections[0].content 
formData.append("provisional[field_of_invention]", type);
formData.append("provisional[field_of_invention_specification]", content);

content=this.state.sections[1].content 
type=this.state.sections[1].title 
formData.append("provisional[background_of_invention]", type);
formData.append("provisional[background_of_invention_specification]", content);

 content=this.state.sections[2].content 
 type=this.state.sections[2].title 
 formData.append("provisional[summary_of_invention]", type);
 formData.append("provisional[summary_of_invention_specification]", content);
 

 type=this.state.sections[3].title 
 content=this.state.sections[3].content 
 formData.append("provisional[detailed_description_of_invention]", type);
 formData.append("provisional[detailed_description_of_invention_specification]", content);

const requestMessage = new Message(
  getName(MessageEnum.RestAPIRequestMessage)
);

this.apiSaveProvsonalDetailsCallID = requestMessage.messageId;

requestMessage.addData(
  getName(MessageEnum.RestAPIResponceEndPointMessage),
  `bx_block_landingpage2/provisional_specifications` 
);

requestMessage.addData(
  getName(MessageEnum.RestAPIRequestHeaderMessage),
  JSON.stringify(header)
);

requestMessage.addData(
  getName(MessageEnum.RestAPIRequestBodyMessage),
  formData
);

requestMessage.addData(
  getName(MessageEnum.RestAPIRequestMethodMessage),
  configJSON.exampleAPiMethod
);

runEngine.sendMessage(requestMessage.id, requestMessage);




}

  // Customizable Area End
}
// Customizable Area Start

// Customizable Area End