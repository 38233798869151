import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import ReactQuill from 'react-quill';
import { createRef, RefObject } from "react";
import { getStorageData, removeStorageData, setStorageData } from "../../../framework/src/Utilities";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";

export interface Section {
  index: number;
  content: string;
  undoStack: { index: number; content: string }[]; 
  redoStack: { index: number; content: string }[];
  title: string;
  subtitle: string;
  prompt:string
}

const sectionsIntialData: Section[] = [
  {
    title: "Summary of the invention",
    index: 5,
    subtitle: "What is the Summary for your invention?",
    content: '',undoStack: [], redoStack: [],
    prompt:'',
  },
  {
    subtitle: "What is the Abstract for your invention?",
    title: "Abstract of the invention",
    index: 2,undoStack: [], redoStack: [],
    content: '',
    prompt:'',

  },
  {
    title: "Background of the invention",undoStack: [], redoStack: [],
    subtitle: "What is the Background for your invention?",
    index: 3,
    content: '',
    prompt:'',
  },
  {
    title: "Title of the invention",
    content: '',
    subtitle: "What is the title for your invention?",
    index: 1,
    prompt:'',undoStack: [], redoStack: []
  },
  {
    title: "Objective of the invention",
    subtitle: "What is the Objective for your invention?",
    index: 4,    
    prompt:'',
    content: '',undoStack: [], redoStack: []
  },
 
];
// Define the interface for the entire API response

interface Specification 
{
  content: string | string[];
}

export interface ApiResponse 
{
  id:number;
  Specification: Specification;
}

// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  // Customizable Area Start
   navigation: any;
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  redoStack: { index: number; content: string }[][];
  sections:Section[]
  rewriteindexcall:number
  undoStack: { index: number; content: string }[][];
  loading:boolean
  abstract:string
  errors:boolean
  nonprrovisonalid:number|null
  disablerewrite:boolean
  selection:string
  promptindexcall:number
  disableprompt:boolean
  // Customizable Area End
}

interface SS {
  id: any;
}

export default class ProvisionalSpecificationPageController extends BlockComponent<
  Props,
  S,
  SS
> {
  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = 
    [ 
      getName(MessageEnum.SessionSaveMessage),
      getName(MessageEnum.SessionResponseMessage),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.NavigationPayLoadMessage),
      getName(MessageEnum.RestAPIResponceDataMessage),
     
    ];

    this.state = {
      errors:false,
      promptindexcall:0,
      undoStack:[],
      disablerewrite:false,
      nonprrovisonalid:0,
      sections:sectionsIntialData,
      loading:true,
      abstract:'',
      selection:'',
      disableprompt:false,
      rewriteindexcall:0,
      redoStack:[],
    };

    // Customizable Area End
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    runEngine.debugLog("Message Recived", message);
    const apiRequestCallIdEndpoint = message.getData(
      getName(MessageEnum.RestAPIResponceDataMessage)
    );

    const responseJsonVariable = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );
    const tokenExpire = responseJsonVariable.errors?.[0]?.token
    if (tokenExpire) {
      this.goToLogIn()
    }
    else if (apiRequestCallIdEndpoint === this.apiProvisonalDetailAPIEndPoint) {
      if (responseJsonVariable.Specification) {
        const sectionsData = this.mapApiResponseToSpecification(responseJsonVariable);
        sectionsData.forEach((section) => {
          this.quillRefsList[`quillRef${section.index}`] = createRef();
        });
        this.setState({ sections: sectionsData,loading: false, nonprrovisonalid: responseJsonVariable.id })
        setStorageData("nonprrovisonalid", responseJsonVariable.id)
      }
      else  if (responseJsonVariable.errors?.length) {
        this.setState({
          loading: false,
          errors: true,
        }, () => {
          setStorageData("BackTrue", "BackTrue")
        })
      }
      else{
        this.setState({
          errors: true,loading: false
        })
      }
      setStorageData("BackTrue", "BackTrue")
    }
    else if (apiRequestCallIdEndpoint === this.apiSaveProvsonalCallID) {
      this.setState({ loading: false })
    }
    else if (apiRequestCallIdEndpoint === this.apipromptCallID) {

      this.updateSectionContentPrompt(responseJsonVariable)

    }
    else  if (apiRequestCallIdEndpoint === this.apiProvisionalSpecificationsAPIEndPoint) {
      const sectionsData = this.mapApiResponseSave(responseJsonVariable);
      sectionsData.forEach((section) => {
        this.quillRefsList[`quillRef${section.index}`] = createRef();
      });
      this.setState({
        sections: sectionsData,
        loading: false,
        errors: false
      })
    }
    else if (apiRequestCallIdEndpoint === this.apiRewriteCallID) {

      this.updateContentBasedOnResponseRewrite(responseJsonVariable)

    }
    
    // Customizable Area End
  }

  // Customizable Area Start
  apiProvisonalDetailAPIEndPoint:string=""
  apipromptCallID:string=""
  apiRewriteCallID:string=""
  apiProvisionalSpecificationsAPIEndPoint:string=""
  apiSaveProvsonalCallID:string=""
  private interval?: NodeJS.Timeout;
  quillRefsList : { [key: string]: RefObject<ReactQuill> } = {};

  async componentDidMount() {
    const loginToke = await getStorageData("token")
    if(!loginToke){
      this.goToLogIn()
    }    

    const searchString = await getStorageData("advanchData");
    const id = await getStorageData("nonprrovisonalid")
  
    this.setState({
      abstract: searchString,
      nonprrovisonalid: id,
    });
  
    if (id) {
      await this.getSavedSPecificationNonProvisonal();
    } else {
      await this.getProvisionalDetails();
    }
  
    this.interval = setInterval(this.handelSaved, 15000);
  };

  
  updateSectionContentPrompt = (responseJsonVariable:any) => {
    //Prompt API
    const responseText = responseJsonVariable.Specification.join(""); 
    const indexmap = this.state.promptindexcall;
  
    this.setState(prevState => ({
      sections: prevState.sections.map((section, index) => ({
        ...section,
        prompt: index === indexmap ? responseText : section.prompt
      }))
    }), () => {
      this.setState({ 
        disableprompt: false 
      });
      this.setState({ 
        loading: false 
      });
    });
  }
  
  updateContentBasedOnResponseRewrite = (responseJsonVariable:any) => {
    //Rewrite Api Response
    if (responseJsonVariable.errors) {
      this.setState({
         errors: true 
        });
    } 
    else if(responseJsonVariable.status===500)
    {
        this.setState({ errors: true });
    } 
    else {
      const indexmap = this.state.rewriteindexcall;
      this.setState(prevState => ({
        sections: prevState.sections.map((section, index) => ({
          ...section,
          content: index === indexmap
          ? responseJsonVariable.Specification.join(' ') 
            : section.content
        }))
      }), () => {
        this.setState({ 
          disablerewrite: false 
        });
      });
    }
  }
  
  async componentWillUnmount() {
    if (this.interval) clearInterval(this.interval);
  }
  
  handleMouse = (): void => {
    const selected = window.getSelection();
    if (selected && selected.rangeCount > 0) {
      const selectedText = selected.toString().trim();
      if (selectedText) {
        this.setState({
           selection: selectedText 
          });
      }
    }
  };
  
  goToLogIn = async () => {

    removeStorageData("token");

    const message: Message = new Message(
      getName(MessageEnum.NavigationMessage)
    );

    message.addData(
      getName(MessageEnum.NavigationTargetMessage),
      "EmailAccountLoginBlock"
    );

    message.addData(getName(MessageEnum.NavigationPropsMessage), this.props);

    const raiseMessage: Message = new Message(
      getName(MessageEnum.NavigationPayLoadMessage)
    );

    message.addData(getName(MessageEnum.NavigationRaiseMessage), raiseMessage);

    this.send(message);
  };

  mapApiResponseToSpecification = (apiResponse: ApiResponse): Section[] => {
    // First Basic Call MAin
    const getCoString = (content: string | string[]): string => {
      let contentString;
       if (Array.isArray(content)) {
        contentString = content.join(" ");
      }
      else if (typeof content === "string") {
        contentString = content;
      }
       else {
        contentString = "";
      }
      return contentString;
    }
    
      const contentArray = Object.entries(apiResponse.Specification).map(([key, value]) => ({
        title: key,
        content: getCoString(value.content)
      }));    

      return [
        {
          
          content: contentArray[0].content,
          title: contentArray[0].title,
          subtitle: "What is the title for your invention?",
          index: 0, undoStack: [], redoStack: [],
          prompt:'',
        },
        {
          title:  contentArray[1].title,
          content: contentArray[1].content,
          prompt:'', undoStack: [], redoStack: [],
          subtitle: "What is the Background for your invention?",
          index: 1,
        
        },
        {
          subtitle: "What is the Summary for your invention?",
          index: 2,
          title: contentArray[2].title,
          content: contentArray[2].content,
          prompt:'', undoStack: [], redoStack: []
        },
        {
          subtitle: "What are the drawing or illustrations for your invention?",
          index: 3,
          title: contentArray[6].title,
          content: contentArray[6].content,
          prompt:'', undoStack: [], redoStack: []
        },
        {
          title:  contentArray[3].title,
          subtitle: "What is the Objective for your invention?",
          index: 4,
          content: contentArray[3].content,  undoStack: [], redoStack: [],
          prompt:'',
        },
        {
          title: contentArray[4].title,
          subtitle: "What is the Claim for your invention?",
          index: 5, undoStack: [], redoStack: [],
          content: contentArray[4].content,
          prompt:'',
        },        
        {
          title: contentArray[5].title,
          subtitle: "What is the Abstract for your invention?",
          index: 6,
          content: contentArray[5].content,
          prompt:'', undoStack: [], redoStack: []
        },
      ]; 
  };


  mapApiResponseSave = (apiResponse: any): Section[] => {    
// After Saved data return on refresh
      return [
        {
          title: apiResponse.document.field_of_invention,
          content: apiResponse.document.field_of_invention_specification,
          subtitle: "What is the title for your invention?",
          index: 0,
          prompt:'', undoStack: [], redoStack: []
        },
        {
          subtitle: "What is the Background for your invention?",
          index: 1, undoStack: [], redoStack: [],
          title:  apiResponse.document.background_of_invention,          
          content: apiResponse.document.background_of_invention_specification,
          prompt:'',
        },
        {
          title: apiResponse.document.summary_of_invention,
          subtitle: "What is the Summary for your invention?",
          index: 2, undoStack: [], redoStack: [],
          content: apiResponse.document.summary_of_invention_specification,
          prompt:'',
        },
        {
          subtitle: "What are the drawings or illustrations for your invention?",
          index:3, undoStack: [], redoStack: [],
          title:  apiResponse.document.drawings_or_illustrations,         
          content: apiResponse.document.drawings_or_illustrations_specifications, 
          prompt:'',
        },
        {
          subtitle: "What is the Objective for your invention?",
          index:4, undoStack: [], redoStack: [],
          title:  apiResponse.document.detailed_description_of_invention,         
          content: apiResponse.document.detailed_description_of_invention_specification, 
          prompt:'',
        },
        {
          title: apiResponse.document.claims,
          subtitle: "What is the Claim for your invention?",        
          prompt:'', undoStack: [], redoStack: [],
          index: 5,
          content: apiResponse.document.claims_specifications,
        },
        {
          title: apiResponse.document.abstract,
          subtitle: "What is the Abstract for your invention?",
          index: 6,
          content: apiResponse.document.abstract_specifications,
          prompt:'', undoStack: [], redoStack: []
        },
      ]; 
  };

  getSavedSPecificationNonProvisonal = async () => {
    this.setState({ 
      loading: true 
    });
    const header = {
      token: await getStorageData("token"),
    };

    const nonprrovisonalid = this.state.nonprrovisonalid;
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.apiProvisionalSpecificationsAPIEndPoint = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `bx_block_landingpage2/provisional_specifications/${nonprrovisonalid}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  handlePrompt = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>, targetIndex: number) => {
    const { value } = event.target; 
    
    this.setState(prevState => ({
      sections: prevState.sections.map((section, indx) => ({
        ...section,
        prompt: indx === targetIndex ? value : section.prompt // Update content for a specificsection
      }))
    }));
    this.setState({promptindexcall:targetIndex});
  };

  ReplaceApiCall=async (Index:number)=>
  {
    const currentSection = this.state.sections[Index]
    const currentQuillRef = this.quillRefsList[`quillRef${currentSection.index}`]?.current;
    if (currentQuillRef) {
      const edit = currentQuillRef.getEditor(); 
      const selectedArea = edit.getSelection(); 

      if (selectedArea && selectedArea.length > 0) {
        edit.deleteText(selectedArea.index, selectedArea.length);
        edit.insertText(selectedArea.index, currentSection.prompt); 
        const newContent = edit.root.innerHTML;
        this.setState(prevState => ({
          sections: prevState.sections.map((section, index) => ({
            ...section,
            prompt: index === Index ? "" : section.prompt,
            content:index===Index ? newContent : section.content,
          }))
        }));
        this.setState({promptindexcall:Index});
      } 
    }
  }

  RewriteApiCall=async(index:number)=>{
    const title=this .state.sections[index].title 
    this.setState({disablerewrite:true})
    const content=this.state.sections[index].content;
    this.setState({rewriteindexcall:index})

    const header = 
    {
      "Content-Type": configJSON.validationApiContentType,
      token: await getStorageData("token"),
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.apiRewriteCallID = requestMessage.messageId;
    
    const params = new URLSearchParams({
      type:"non_provisional",
      abstract: content,
      section:title
    }).toString();
    
    requestMessage.addData
    (
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `bx_block_landingpage2/provisional_specifications/rewrite_specification?${params}`
    );

    requestMessage.addData
    (
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData
    (
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);

  }


  promptApiCall=async (index:number)=>
  {
    this.setState({disableprompt:true})
    const selectied=this.state.selection;
    const prompts=this.state.sections[index].prompt;
    const title=this .state.sections[index].title 
    const header = {
      "Content-Type": configJSON.validationApiContentType,
      token: await getStorageData("token"),
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.apipromptCallID = requestMessage.messageId;
    
    const params = new URLSearchParams(
    {
      type:title,
      abstract: selectied,
      prompt: prompts,
    }
  ).toString();

    requestMessage.addData
    (
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.apipromptEndPoint}?${params}`
    );

    requestMessage.addData
    (
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData
    (
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);

  }

  getProvisionalDetails = async () => {
    const header = {
      token: await getStorageData("token"),
    };
    let promptId = await getStorageData("promptId")
    const userstring=this.state.abstract
    const specification_type='non_provisional'
    const params = new URLSearchParams({
      abstract: userstring,
      specification_type: specification_type,
      prompt_id:promptId
    }).toString();

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.apiProvisonalDetailAPIEndPoint = requestMessage.messageId;

    requestMessage.addData
    (
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `bx_block_landingpage2/provisional_specifications/generate_provisional_specification?${params}`
    );

    requestMessage.addData
    (
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData
    (
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };
  

  handleContentChange(value: string, tIndex: number) {
    this.setState(prevState => {
      const updatedSec= prevState.sections.map((section, index) => {
        if (index === tIndex) {
          // Update specific section content and manage undo/redo stacks
          return {
            ...section,
            content: value,
            undoStack: [
              ...section.undoStack,
              { index: tIndex, content: section.content }
            ],
            redoStack: [] // Clear redo stack on new change
          };
        }
        return section;
      });
      return { sections: updatedSec };
    });
  }

  handleUndo(index: number) {
    this.setState(prevState => {
      const section = prevState.sections[index];
      if (section.undoStack.length === 0) return null; // No action to undo

      const lastState = section.undoStack.pop(); // Get the last state from undo stack
      if (!lastState) return null; // If no last state, exit early

      const previousContent = lastState.content;

      // Push the current state to the redo stack
      const redoStack = [
        ...section.redoStack,
        { index, content: section.content }]

      const updatedSections = prevState.sections.map((sec, idx) => ({
        ...sec,
        content: idx === index ? previousContent : sec.content,
        undoStack: idx === index ? section.undoStack : sec.undoStack,
        redoStack
      }));

      return {
        sections: updatedSections
      };
    });
  }

  handleRedo(index: number) {
    this.setState(prevState => {
      const section = prevState.sections[index];
      if (section.redoStack.length === 0) return null; // No action to redo

      const nextState = section.redoStack.pop(); // Get the last state from redo stack
      if (!nextState) return null; // If no next state, exit early

      const nextContent = nextState.content;

      // Push the current state to the undo stack
      const undoStack = [
        ...section.undoStack,
        { index, content: section.content }
      ];

      const updatedSections = prevState.sections.map((sec, idx) => ({
        ...sec,
        content: idx === index ? nextContent : sec.content,
        undoStack,
        redoStack: idx === index ? section.redoStack : sec.redoStack
      }));

      return {
        sections: updatedSections
      };
    });
  }

handelSaved=()=>{
  const id=this.state.nonprrovisonalid;
  if(id!=null)
   this.handelSave();
}

handelSave=async()=>{

const header = {
  token: await getStorageData("token"),
};

const formData = new FormData();

let nonprrovisonalid = await getStorageData("nonprrovisonalid")
setStorageData("Specid",nonprrovisonalid )

formData.append("id",nonprrovisonalid);
//First index Title->invention
let typesend=this.state.sections[0].title 
let contentsend=this.state.sections[0].content 
formData.append("provisional[field_of_invention]", typesend);
formData.append("provisional[field_of_invention_specification]", contentsend);

//second index Backround
contentsend=this.state.sections[1].content 
typesend=this.state.sections[1].title 
formData.append("provisional[background_of_invention]", typesend);
formData.append("provisional[background_of_invention_specification]", contentsend);

//third index  summary
contentsend=this.state.sections[2].content 
typesend=this.state.sections[2].title 
formData.append("provisional[summary_of_invention]", typesend);
formData.append("provisional[summary_of_invention_specification]", contentsend);

contentsend=this.state.sections[3].content 
typesend=this.state.sections[3].title 
formData.append("provisional[drawings_or_illustrations]", typesend);
formData.append("provisional[drawings_or_illustrations_specifications]", contentsend);

typesend=this.state.sections[4].title 
contentsend=this.state.sections[4].content 
formData.append("provisional[detailed_description_of_invention]", typesend);
formData.append("provisional[detailed_description_of_invention_specification]", contentsend);

contentsend=this.state.sections[5].content 
typesend=this.state.sections[5].title 
formData.append("provisional[claims]", typesend);
formData.append("provisional[claims_specifications]", contentsend);

contentsend=this.state.sections[6].content 
typesend=this.state.sections[6].title 
formData.append("provisional[abstract]", typesend);
formData.append("provisional[abstract_specifications]", contentsend);

const requestMessage = new Message(
  getName(MessageEnum.RestAPIRequestMessage)
);

this.apiSaveProvsonalCallID = requestMessage.messageId;

requestMessage.addData(
  getName(MessageEnum.RestAPIResponceEndPointMessage),
  `bx_block_landingpage2/provisional_specifications` 
);

requestMessage.addData(
  getName(MessageEnum.RestAPIRequestHeaderMessage),
  JSON.stringify(header)
);

requestMessage.addData(
  getName(MessageEnum.RestAPIRequestBodyMessage),
  formData
);

requestMessage.addData(
  getName(MessageEnum.RestAPIRequestMethodMessage),
  configJSON.exampleAPiMethod
);

runEngine.sendMessage(requestMessage.id, requestMessage);

}

goingToSpecificationPage = async () => {
  this.setState({loading:true})
    await this.handelSave();
    const message = new Message(getName(MessageEnum.NavigationMessage));
    message.addData(getName(MessageEnum.NavigationTargetMessage), "SpecificationPage");
    message.addData(getName(MessageEnum.NavigationPropsMessage), this.props); 
    this.send(message);
};

  // Customizable Area End
}
// Customizable Area Start

// Customizable Area End