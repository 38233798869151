import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { getStorageData, setStorageData } from "../../../framework/src/Utilities";

// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  downloadModel: boolean
  publication_number: string 
  loading: boolean;
  detailsData: any;
  patentNumbers: string[];
  clearTrigger:boolean
  // Customizable Area End
}

interface SS {
  id: any;
}

export default class LandingPageShowMappingController extends BlockComponent<
  Props,
  S,
  SS
> {
  apiShowMappingDetailsAPIEndPoint: string = ""; 
  apiGetPatentDownloadreportMappingCallId: string = ""
  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [getName(MessageEnum.RestAPIResponceMessage),  getName(MessageEnum.NavigationPayLoadMessage),

    ];
    this.state = {
      detailsData: { mapping: [] }, 
      patentNumbers:[],
      clearTrigger:false,
      downloadModel: false,
      publication_number: "",
      loading: false,
    };
    // Customizable Area End
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    runEngine.debugLog("Message Recived", message);
    const apiRequestCallId = message.getData(
      getName(MessageEnum.RestAPIResponceDataMessage)
    );
    const responseJson = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );
    if (apiRequestCallId == this.apiGetPatentDownloadreportMappingCallId) {
      if (responseJson) {
        this.setState({
          clearTrigger: true
        })
      }
    }
    else  if (apiRequestCallId === this.apiShowMappingDetailsAPIEndPoint) {
      if (responseJson.error)
        this.setState({ loading: false })
      else {
        const formattedData = this.transformData(responseJson);
        this.setState({ detailsData: formattedData, loading: false })
      }
    } 
    // Customizable Area End
  }

  // Customizable Area Start

  async componentDidMount() {
    const publication_number =  await getStorageData("publication_number")
    this.setState({publication_number:publication_number,patentNumbers:publication_number})
    this.getPatentDetailsAPI();      
   }

   transformData = (data: any) => {
    const mappings: Array<{
        keyFeature: string;
        keywords: string[];
        overlappingPercentage: number;
        matchingText: {
            fromAbstract: string;
            fromDescription: string;
            fromClaims: string;
        };
    }> = [];

    let aggregateOverlapPercentage = 0;

    const extractOverlappingPercentage = (line: string): number => {
        const match = line.match(/(?:\d{1,3}(?:\.\d+)?|\.\d+)%/);
        return match ? parseFloat(match[0]) : 0;
    };

    const extractKeywords = (line: string): string[] => {
        return line.split(": ")[1]?.split(",").map((keyword: string) => keyword.trim()) || [];
    };

    const extractMatchingText = (startIndex: number): { fromAbstract: string; fromDescription: string; fromClaims: string } => {
        const matchingText = {
            fromAbstract: "N/A",
            fromDescription: "N/A",
            fromClaims: "N/A",
        };

        for (let j = startIndex + 3; j < data.mapping.length && !data.mapping[j].startsWith("Key Feature:"); j++) {
            const matchingLine = data.mapping[j]?.trim();
            if (matchingLine?.startsWith("- From Abstract:")) {
                matchingText.fromAbstract = matchingLine.split(": ")[1]?.trim() || "N/A";
            } else if (matchingLine?.startsWith("- From Description:")) {
                matchingText.fromDescription = matchingLine.split(": ")[1]?.trim() || "N/A";
            } else if (matchingLine?.startsWith("- From Claims:")) {
                matchingText.fromClaims = matchingLine.split(": ")[1]?.trim() || "N/A";
            }
        }
        return matchingText;
    };

    const processKeyFeature = (startIndex: number) => {
        const keyFeature = data.mapping[startIndex].split(": ")[1]?.trim() || "N/A";
        const keywords = extractKeywords(data.mapping[startIndex + 1]);
        const overlappingPercentage = extractOverlappingPercentage(data.mapping[startIndex + 2]);
        const matchingText = extractMatchingText(startIndex);

        return {
            keyFeature,
            keywords,
            overlappingPercentage,
            matchingText,
        };
    };

    const processMappings = () => {
        let index = 0;
        while (index < data.mapping.length) {
            const line = data.mapping[index]?.trim();
            if (line?.startsWith("Key Feature:")) {
                mappings.push(processKeyFeature(index));
                index += 4; // Move past the processed lines (current line + 3 more)
            } else if (line?.startsWith("Total Aggregate Overlap Percentage:")) {
                aggregateOverlapPercentage = extractOverlappingPercentage(line);
                index++; // Move to the next line after processing the aggregate percentage
            } else {
                index++; // Move to the next line for any other case
            }
        }
    };

    processMappings();
    
    return {
        mapping: mappings,
        aggregateOverlapPercentage,
    };
};



  getPatentDetailsAPI = async () => {
    this.setState({ loading: true });
    const header = {
      token: await getStorageData("token"),
    };
    const publication_number=this.state.publication_number
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.apiShowMappingDetailsAPIEndPoint = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `/bx_block_landingpage2/mappings/keyword_mapping?publication_number=${publication_number}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };


  downloadDetails = () => {
    this.downloadOpenModel()
    this.getPatentDownloadreportIndividuall(this.state.patentNumbers)
  }

  getPatentDownloadreportIndividuall = async (patentNumbers: any) => {
    const header = {
      "Content-Type": "application/json",
      token: await getStorageData("token")
    };
    const Body = { "publication_numbers": [patentNumbers], "case_id":await getStorageData("caseid")   };
    const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
    this.apiGetPatentDownloadreportMappingCallId = requestMessage.messageId;
    requestMessage.addData(getName(MessageEnum.RestAPIResponceEndPointMessage), `bx_block_advanced_search/patent_download_report.pdf`);
    requestMessage.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(header));
    requestMessage.addData(getName(MessageEnum.RestAPIRequestBodyMessage), JSON.stringify(Body));
    requestMessage.addData(getName(MessageEnum.RestAPIRequestMethodMessage), 'POST');
    runEngine.sendMessage(requestMessage.id, requestMessage);

  }
  downloadCloseModel = () => {
    this.setState({ downloadModel: false })
  };

  downloadOpenModel = () => {
    this.setState({ downloadModel: true })
  };

  goToPatentDetails = () => {
    this.props.navigation.goBack();
  }
  // Customizable Area End
}
