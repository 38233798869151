// App.js - WEB
import React, { Component } from 'react';
import { View } from 'react-native';
import firebase from 'firebase';
import { connect } from 'react-firebase';
import { BuilderProvider } from '@builder/component-library';

import WebRoutesGenerator from '../../components/src/NativeWebRouteWrapper';
import { ModalContainer } from 'react-router-modal';
import HomeScreen from '../../components/src/HomeScreen';
import TopNav from '../../components/src/TopNav';
import InfoPage from '../../blocks/info-page/src/InfoPageBlock';
import AlertBlock from '../../blocks/alert/src/AlertBlock';
import Adminconsole2 from "../../blocks/adminconsole2/src/Adminconsole2";
import ApiIntegration from "../../blocks/apiintegration/src/ApiIntegration";
import BulkUploading from "../../blocks/bulkuploading/src/BulkUploading";
import LandingPage from "../../blocks/landingpage/src/LandingPage";
import Analytics from "../../blocks/analytics/src/Analytics";
import OTPInputAuth from "../../blocks/otp-input-confirmation/src/OTPInputAuth";
import PhoneNumberInput from "../../blocks/mobile-account-registration/src/PhoneNumberInput";
import AdditionalDetailForm from "../../blocks/mobile-account-registration/src/AdditionalDetailForm";
import CountryCodeSelector from "../../blocks/country-code-selector/src/CountryCodeSelector";
import EmailAccountRegistration from "../../blocks/email-account-registration/src/EmailAccountRegistration.web";
import EmailAccountLoginBlock from "../../blocks/email-account-login/src/EmailAccountLoginBlock.web";
import SocialMediaAccountLogin from "../../blocks/social-media-account/src/SocialMediaAccountLogin";
import SocialMediaAccountRegistration from "../../blocks/social-media-account/src/SocialMediaAccountRegistration";
import DownloadOptions from "../../blocks/downloadoptions/src/DownloadOptions";
import Cfgeneratemapping1 from "../../blocks/cfgeneratemapping1/src/Cfgeneratemapping1";
import AdvancedSearch from "../../blocks/advancedsearch/src/AdvancedSearch";
import Cfsearchresultsaggregationanddisplay from "../../blocks/cfsearchresultsaggregationanddisplay/src/Cfsearchresultsaggregationanddisplay";
import Cfpromptmanagerforchatgpt1 from "../../blocks/cfpromptmanagerforchatgpt1/src/Cfpromptmanagerforchatgpt1";
import Keywordextractor2 from "../../blocks/keywordextractor2/src/Keywordextractor2";
import Cfaienhancedsearchalgorithm from "../../blocks/cfaienhancedsearchalgorithm/src/Cfaienhancedsearchalgorithm";
import Rolesandpermissions2 from "../../blocks/rolesandpermissions2/src/Rolesandpermissions2";
import ForgotPassword from "../../blocks/forgot-password/src/ForgotPassword";
import ForgotPasswordOTP from "../../blocks/forgot-password/src/ForgotPasswordOTP";
import NewPassword from "../../blocks/forgot-password/src/NewPassword";
import Settings2 from "../../blocks/settings2/src/Settings2";
import Cfgooglescholarapi from "../../blocks/cfgooglescholarapi/src/Cfgooglescholarapi";
import TermsConditions from "../../blocks/termsconditions/src/TermsConditions";
import TermsConditionsDetail from "../../blocks/termsconditions/src/TermsConditionsDetail";
import TermsConditionsUsers from "../../blocks/termsconditions/src/TermsConditionsUsers";
import Categoriessubcategories from "../../blocks/categoriessubcategories/src/Categoriessubcategories";
import CustomisableUserProfiles from "../../blocks/customisableuserprofiles/src/CustomisableUserProfiles.web"
import UserProfilesAccount from "../../blocks/customisableuserprofiles/src/UserProfilesAccount.web"
import UserProfilesRecentSearch from "../../blocks/customisableuserprofiles/src/UserProfilesRecentSearch.web"
import LandingPageWeb from "../../blocks/landingpage/src/LandingPage.web";
import KeyFeatureLandingPageWeb from "../../blocks/landingpage/src/KeyFeatureLandingPage.web";
import LandingPageViewReportClaimsWeb from "../../blocks/landingpage/src/LandingPageViewReportClaims.web";
import LandingPageShowMapping from "../../blocks/landingpage/src/LandingPageShowMapping.web";
import ClassificationLandingPage from "../../blocks/landingpage/src/ClassificationLandingPage.web";
import ProductDetailsLandingPage from "../../blocks/landingpage/src/ProductDetailsLandingPage.web";
import SimilarDocuments from "../../blocks/landingpage/src/SimilarDocuments.web"
import PublicationSimilarDocuments from '../../blocks/landingpage/src/PublicationSimilarDocument.web';
import PublicationShowMapping from '../../blocks/landingpage/src/PublicationShowMapping.web'
import ProductShowMapping from '../../blocks/landingpage/src/ProductShowMapping.web'
import SpecificationPage from '../../blocks/landingpage/src/SpecificationPage.web';
const routeMap = {
SpecificationPage:{
  component:SpecificationPage,
 path:"/specificationreport"
},
Adminconsole2:{
 component:Adminconsole2,
path:"/Adminconsole2"},
ApiIntegration:{
 component:ApiIntegration,
path:"/ApiIntegration"},
BulkUploading:{
 component:BulkUploading,
path:"/BulkUploading"},
LandingPageWeb:{
 component:LandingPageWeb,
path:"/landingpage"},
KeyFeatureLandingPageWeb: {
  component: KeyFeatureLandingPageWeb,
path: "/resultspage"},
LandingPageViewReportDescriptionWeb: {
  component: LandingPageViewReportClaimsWeb,
path: "/patentdetail/:id"},
LandingPageShowMapping: {
  component: LandingPageShowMapping,
path: "/patentmapping"},
ClassificationLandingPage: {
  component: ClassificationLandingPage,
path: "/publicationdetail/:idscholar/:id"},
ProductDetailsLandingPage: {
  component: ProductDetailsLandingPage,
path: "/:from/productdetail/:id"},
LandingPage:{
  component:LandingPage,
 path:"/LandingPages"},
Analytics:{
 component:Analytics,
path:"/Analytics"},
OTPInputAuth:{
 component:OTPInputAuth,
path:"/OTPInputAuth"},
PhoneNumberInput:{
 component:PhoneNumberInput,
path:"/PhoneNumberInput"},
AdditionalDetailForm:{
 component:AdditionalDetailForm,
path:"/AdditionalDetailForm"},
CountryCodeSelector:{
 component:CountryCodeSelector,
path:"/CountryCodeSelector"},
EmailAccountRegistration:{
 component:EmailAccountRegistration,
path:"/signup"},
EmailAccountLoginBlock:{
 component:EmailAccountLoginBlock,
path:"/login"},
SocialMediaAccountLogin:{
 component:SocialMediaAccountLogin,
path:"/SocialMediaAccountLogin"},
SocialMediaAccountRegistration:{
 component:SocialMediaAccountRegistration,
path:"/SocialMediaAccountRegistration"},
DownloadOptions:{
 component:DownloadOptions,
path:"/DownloadOptions"},
Cfgeneratemapping1:{
 component:Cfgeneratemapping1,
path:"/Cfgeneratemapping1"},
AdvancedSearch:{
 component:AdvancedSearch,
path:"/AdvancedSearch"},
Cfsearchresultsaggregationanddisplay:{
 component:Cfsearchresultsaggregationanddisplay,
path:"/Cfsearchresultsaggregationanddisplay"},
Cfpromptmanagerforchatgpt1:{
 component:Cfpromptmanagerforchatgpt1,
path:"/Cfpromptmanagerforchatgpt1"},
Keywordextractor2:{
 component:Keywordextractor2,
path:"/Keywordextractor2"},
Cfaienhancedsearchalgorithm:{
 component:Cfaienhancedsearchalgorithm,
path:"/Cfaienhancedsearchalgorithm"},
Rolesandpermissions2:{
 component:Rolesandpermissions2,
path:"/Rolesandpermissions2"},
ForgotPassword:{
 component:ForgotPassword,
path:"/ForgotPassword"},
ForgotPasswordOTP:{
 component:ForgotPasswordOTP,
path:"/ForgotPasswordOTP"},
NewPassword:{
 component:NewPassword,
path:"/NewPassword"},
Settings2:{
 component:Settings2,
path:"/Settings2"},
Cfgooglescholarapi:{
 component:Cfgooglescholarapi,
path:"/Cfgooglescholarapi"},
TermsConditions:{
 component:TermsConditions,
path:"/TermsConditions"},
TermsConditionsDetail:{
 component:TermsConditionsDetail,
path:"/TermsConditionsDetail"},
TermsConditionsUsers:{
 component:TermsConditionsUsers,
path:"/TermsConditionsUsers"},
Categoriessubcategories:{
 component:Categoriessubcategories,
path:"/Categoriessubcategories"},

  Home: {
    component: HomeScreen,
    path: '/',
    exact: true,
  },
  InfoPage: {
    component: InfoPage,
    path: '/InfoPage',
  },

  Alert: {
    component: AlertBlock,
    path: "*/Alert",
    modal: true
  }

  ,
  CustomisableUserProfiles: {
    component: CustomisableUserProfiles,
    path: "/profile",
    modal: false
  },
  UserProfilesAccount: {
    component: UserProfilesAccount,
    path: "/password",
    modal: false
  },
  UserProfilesRecentSearch: {
    component: UserProfilesRecentSearch,
    path: "/searches",
    modal: false
  },
  SimilarDocuments: {
    component: SimilarDocuments,
    path: "/SimilarDocuments/:id",
    modal: false
  },
  PublicationSimilarDocuments: {
    component: PublicationSimilarDocuments,
    path: "/PublicationSimilarDocuments/:idscholar/:id",
    modal: false
  },
  PublicationShowMapping: {
    component: PublicationShowMapping,
    path: "/publicationmapping",
    modal: false
  },
  ProductShowMapping: {
    component: ProductShowMapping,
    path: "/productmapping",
    modal: false
  }
};

const firebaseAPI = firebase.initializeApp({
  apiKey: 'AIzaSyDgl9aTbKMdRZ9-ijSZRionh3V591gMJl4',
  authDomain: 'rnmasterapp-c11e9.firebaseapp.com',
  databaseURL: 'https://rnmasterapp-c11e9.firebaseio.com',
  projectId: 'rnmasterapp-c11e9',
  storageBucket: 'rnmasterapp-c11e9.appspot.com',
  messagingSenderId: '649592030497',
  appId: '1:649592030497:web:7728bee3f2baef208daa60',
  measurementId: 'G-FYBCF3Z2W3',
});

class App extends Component {
  render() {
    const defaultAnalytics = firebaseAPI.analytics();
    defaultAnalytics.logEvent('APP_Loaded');

    return (
      <BuilderProvider>
        <View style={{ height: '100vh', width: '100%', backgroundColor: "#E8E7E7" }}>
          {WebRoutesGenerator({ routeMap })}
          <ModalContainer />
        </View>
      </BuilderProvider>
    );
  }
}

export default App;
