import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { getStorageData } from "../../../framework/src/Utilities";
import { checkAccountLimitError } from "../../../blocks/utilities/src/ResolveCondition";

interface PatentSimilarDoc {
    is_patent: boolean;
    publication_number: string;
    primary_language: string;
    publication_date: string;
    title: string;
    patent_id: string;
    serpapi_link: string;
};
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  selectedTabs:  string[];
  overlappingData: {label: string, value: number}[];
  girdView: boolean;
  selectResult: string;
  downReportModel: boolean;
  loading: boolean;
  similarDocData: PatentSimilarDoc[];
  selectedPatentNumbers: string[];
  selectedPublicationNumbers: string[];
  selectedProductNumbers:string[];
  clearTrigger:boolean;
  rerundownloadpatent:boolean;
  rerundownloadpublication:boolean;
  rerundownloadproduct:boolean;
  circularLoader:number[]
seletcetdOverview:any
errorOverLappingData:any;
overLappingData:any;
formattedData: any
case_id:any
visibleGraphs: number[];
isAccountLimitExceeded:boolean
  // Customizable Area End
}

interface SS {
  id: any;
}

export default class SimilarDocumentsController extends BlockComponent<
  Props,
  S,
  SS
> {
  apiSimilarDocumnetCallID : string = ""
  apiGetPatentDownloadreportCallId: string = ""
  apiGetPublicationDownloadreportCallId:string=""
  apiGetProductDownloadreportCallId:string=""
  apigetViewOverlappingDataCallID:string=""
  circularLoaderIndex : number = -1;
  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceDataMessage),
      getName(MessageEnum.SessionSaveMessage),
      getName(MessageEnum.SessionResponseMessage),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.NavigationKeyFeatureMessage),
    ];

    this.state = {
      loading: false,
      selectedTabs: [
        "Patent",
      ],
      selectResult:"Patent",
      similarDocData: [],
      overlappingData: [
        { label: 'Total Overlapping', value: 70 },
        { label: 'Abstarct', value: 30 },
        { label: 'Description', value: 40 },
        { label: 'Claims', value: 50 },
      ],
      girdView: false,
      downReportModel: false,
      selectedPatentNumbers: [],
  selectedPublicationNumbers:[],
  selectedProductNumbers:[],
  clearTrigger:false,
  rerundownloadpatent:false,
  rerundownloadpublication:false,
  rerundownloadproduct:true,
  circularLoader: [] ,
  seletcetdOverview:[],
  errorOverLappingData:[],
  overLappingData:[],
  formattedData:[],case_id:'',      visibleGraphs: [],
  isAccountLimitExceeded:false

    };
    // Customizable Area End
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    runEngine.debugLog("Message Recived", message);
    const apiRequestCallId = message.getData(
      getName(MessageEnum.RestAPIResponceDataMessage)
    );

    const responseJson = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );

      if (responseJson.errors?.length) {
        this.setState({
          loading: false
        })
      }
      const tokenExpire = responseJson.errors?.[0]?.token
      if (tokenExpire) {
        this.goingToLogInPage()
      }
     if (apiRequestCallId === this.apiSimilarDocumnetCallID) {      
        if(responseJson?.similar_documents?.length > 0) {
        this.setState({similarDocData :responseJson?.similar_documents, loading: false})
      }else {
        this.setState({ 
          isAccountLimitExceeded:checkAccountLimitError(responseJson?.error,responseJson?.errors)
        })
      }
      }else  if (apiRequestCallId === this.apiGetPatentDownloadreportCallId) {
        this.apiGetPatentDownloadreportCallIdfor(responseJson)
      }else  if (apiRequestCallId === this.apiGetPublicationDownloadreportCallId) {
        this.apiGetPublicationDownloadreportCallIdfor(responseJson)
       
      }else  if (apiRequestCallId === this.apiGetProductDownloadreportCallId) {
        this.apiGetProductDownloadreportCallIdfor(responseJson)
       
      }
     else if (apiRequestCallId === this.apigetViewOverlappingDataCallID) {
      this.apigetViewOverlappingDataCallIDfor(responseJson)
       

      
     }
    
      
    // Customizable Area End
  }

  // Customizable Area Start
  async componentDidMount() {
    const loginToken = await getStorageData("token")
    if (!loginToken) {
      this.goingToLogInPage()
    }
    const similarDocId = this.props.navigation.getParam("id")
    this.getSimilarDocs(similarDocId)
    this.setState({
      case_id: await getStorageData("caseid")

    })
  }
     apiGetPatentDownloadreportCallIdfor=(responseJson:any)=>{
      if (this.state.rerundownloadpatent && responseJson) {
        const selectpatents=this.state.selectedPatentNumbers;
        this.setState({ rerundownloadpatent: false }, () => {
          setTimeout(() => {this.getPatentDownloadreport(selectpatents);}, 3000);
          
        });
      } else if (responseJson) {
        this.setState({
          selectedPatentNumbers: [],
          rerundownloadpatent: false
        });
      }
    }

    apiGetPublicationDownloadreportCallIdfor=(responseJson:any)=>{
        if (this.state.rerundownloadpublication && responseJson) {
          const selectpublication=this.state.selectedPublicationNumbers;
          this.setState({ rerundownloadpublication: false }, () => {
            setTimeout(() => {this.getPublicationDownloadreport(selectpublication);}, 3000);
            
          });
        } else if (responseJson) {
          this.setState({
            selectedPublicationNumbers: [],
            clearTrigger: true
          });
        }
    }
    apiGetProductDownloadreportCallIdfor=(responseJson:any)=>{
        if(this.state.rerundownloadproduct && responseJson)
          {
            this.getProductDownloadReport(this.state.selectedProductNumbers)
            const selectproduct=this.state.selectedProductNumbers;
            this.setState({ rerundownloadproduct: false }, () => {
              setTimeout(() => {this.getProductDownloadReport(selectproduct);}, 3000); 
              
            });
          }    
          else if (responseJson) {
            this.setState({
              selectedProductNumbers: [],
              clearTrigger: true
            });
          }
    }
    apigetViewOverlappingDataCallIDfor=(responseJson:any)=>{      
      if (responseJson.errors || responseJson.error || responseJson?.publication_number == "undefined") {
        this.setState((prevstate) => ({
          
          errorOverLappingData: [...prevstate.errorOverLappingData, responseJson?.status == 500 || responseJson?.publication_number == "undefined" ? 
            {
              message: "Something went wrong please try again later" ,
              index: this.circularLoaderIndex,
            } : responseJson?.errors?.[0]
          ],
          loading: false,
          circularLoader: prevstate.circularLoader.filter(
            (number) => number !== this.circularLoaderIndex
          ),
        }))
        
      }
      else {
        this.setState((prevState) => ({
          overLappingData: [...prevState.overLappingData, responseJson],
          circularLoader: prevState.circularLoader.filter(
            (number) => number !== this.circularLoaderIndex
          ),
          loading: false
        }))
        this.transformOverLappingDataFormat()
      }
    }
  getGridViewStyleClassName = () => {
    return this.state.girdView
      ? "keyFeature-Overlapping-main-box-gridView"
      : "keyFeature-Overlapping-main-box";
  };
    
  getGridViewChartClassName() {
    return  this.state.girdView ? "keyFeature-chart-main-box-gridView" : "keyFeature-chart-main-box";
  }
  toggleGraphVisibility = (pNumber: string , index: number) => {
    this.setState((prevState) => {
      if (!prevState.visibleGraphs.includes(index)) {
        return {
          visibleGraphs: [...prevState.visibleGraphs, index]
        };
      } else {
        return {
          visibleGraphs: prevState.visibleGraphs.filter(id => id !== index)
        };

      }
    });
    const overlapExist = this.state.formattedData.find(
      (formattedItem: any) => formattedItem.publication_number === pNumber
    );
    if(!overlapExist) {
      this.callGetViewOverlappingApi(pNumber,index)
    }
  };
  transformOverLappingDataFormat = () => {
    const formatData = this.state.overLappingData.map((item: any) => {
      try {   
        let parsedMpping = Object.entries(item.mapping).map(([key, value]) => `${key}: ${value}`);


        const formatMapping = parsedMpping.map((entry: any) => {
          const [label, percentage] = entry.split(":");
          const value = parseFloat(percentage.replace("%", "").trim()) || 0;

          return {label: label.trim(),value: value };
        });
        return {publication_number: item.publication_number, mapping: formatMapping };
      } catch (err) {
        return {publication_number: item.publication_number,mapping: []};
      }
    });
    this.setState({ formattedData : formatData });
  };

  goBackToLandingPage = async () => {
    this.props.navigation.goBack();
  };

  handleClickGridView = () => {
    this.setState(prevState => ({ girdView: !prevState.girdView}));
  };

  onClickSetStutus = (value:string) => {
    this.setState({selectResult:value})
  }
  getPatentDownloadreport = async (selectedPatentNumbers: any) => {
    const Body = { "publication_numbers": selectedPatentNumbers, "case_id": await getStorageData("caseid") };
    const header = {
      "Content-Type": "application/json",
      token: await getStorageData("token")
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.apiGetPatentDownloadreportCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `bx_block_advanced_search/patent_download_report.pdf`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(Body)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      'POST'
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);

  }
  getPublicationDownloadreport = async (selectedPublicationNumbers: any) => {

    const Body = { "scholar_ids": selectedPublicationNumbers, "case_id": await getStorageData("caseid") };
    const header = {
      "Content-Type": "application/json",
      token: await getStorageData("token")
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.apiGetPublicationDownloadreportCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `bx_block_advanced_search/publication_download_report`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(Body)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      'POST'
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  }
  getProductDownloadReport = async (selectedProductNumbers: any) => {
    const Body = {
      "product_ids": selectedProductNumbers,
      "case_id": await getStorageData("caseid")
    };
    const header = {
      "Content-Type": "application/json",
      token: await getStorageData("token")
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.apiGetProductDownloadreportCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `bx_block_advanced_search/products_download_report`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(Body)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      'POST'
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  }
  callGetViewOverlappingApi = async (pNumber: string, index: number) => {
    this.circularLoaderIndex = index;
    this.setState((prevState) => ({
      circularLoader: [...prevState.circularLoader, index],
    }));
    const parameters = new URLSearchParams({
      publication_number: pNumber,
    }).toString()
    

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.apigetViewOverlappingDataCallID = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `bx_block_landingpage2/mappings/overlaping_persentage?${parameters}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify({
        "Content-Type": configJSON.validationApiContentType,
        token: await getStorageData("token")
      })
    );


    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  getCaseId=(id:any)=>{
this.setState({seletcetdOverview:id})

  }
  onChangepatentselect = (publicationNumber: string) => {
    this.setState((prevState) => {
      const isAlreadySelected = prevState.selectedPatentNumbers.includes(publicationNumber);
      const updatedSelection = isAlreadySelected
        ? prevState.selectedPatentNumbers.filter((num) => num !== publicationNumber)
        : [...prevState.selectedPatentNumbers, publicationNumber];


      return { selectedPatentNumbers: updatedSelection };
    });
  };
  downloadDetails = () => {
    if ((this.state.selectedPatentNumbers.length > 0) || 
    (this.state.selectedPublicationNumbers.length > 0) || 
    (this.state.selectedProductNumbers.length > 0)) {
      
      this.setState({ clearTrigger: false })
      if (this.state.selectResult === "Patent") {
        this.downloadModelOpen()
        this.setState({rerundownloadpatent:true})
        this.getPatentDownloadreport(this.state.selectedPatentNumbers)
      } else if (this.state.selectResult === "Publication") {  
        this.downloadModelOpen()
        this.setState({rerundownloadpublication:true})
        this.getPublicationDownloadreport(this.state.selectedPublicationNumbers)
      } else if (this.state.selectResult === "Products") {
        this.downloadModelOpen()
        this.setState({rerundownloadproduct:true})
        this.getProductDownloadReport(this.state.selectedProductNumbers)
      }
    }
  }

  downloadModelOpen = () => {

    this.setState({ downReportModel: true })

  };

  downloadModelClose = () => {

    this.setState({ downReportModel: false })

  };

  goToShowMappingPage = async () => {

    const message: Message = new Message(
      getName(MessageEnum.NavigationMessage),
    );
    
    message.addData(
      getName(MessageEnum.NavigationTargetMessage),
      "LandingPageShowMapping"
    );

    message.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    const raiseMessage: Message = new Message(
      getName(MessageEnum.NavigationPayLoadMessage),
    );

    message.addData(getName(MessageEnum.NavigationRaiseMessage), raiseMessage);
    this.send(message);

  };

  goingToLogInPage = async () => {
    const messageLogin: Message = new Message(
      getName(MessageEnum.NavigationMessage),
    );

    messageLogin.addData(
      getName(MessageEnum.NavigationTargetMessage),
      "EmailAccountLoginBlock"
    );

    messageLogin.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    const raiseMessage: Message = new Message(
      getName(MessageEnum.NavigationPayLoadMessage),
    );

    messageLogin.addData(getName(MessageEnum.NavigationRaiseMessage), raiseMessage);
    this.send(messageLogin);
  };

  getSimilarDocs = async (documentId: string) => {

    const header = {token: await getStorageData("token")};

    this.setState({ loading: true });

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.apiSimilarDocumnetCallID = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.getDetailsAPIEndPoint}${documentId}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };
  // Customizable Area End
}
