import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { getStorageData, removeStorageData, setStorageData } from "../../../framework/src/Utilities";

// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  // Customizable Area Start
  navigation:{
    goBack:Function
  }
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  prrovisonalid: string 
  loading: boolean;
  errors:boolean;
  detailsData: any;
  downReportModel:boolean;
  downloadclose:boolean;
  caseid:string;
  // Customizable Area End
}

interface SS {
  id: any;
}

export default class SpecificationPageController extends BlockComponent<
  Props,
  S,
  SS
> {
  apiProvisionalSpecificationsAPIEndPoint: string = ""; 
  apiDownloadReportAPIEndPoint:string="";
  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [getName(MessageEnum.RestAPIResponceMessage),  getName(MessageEnum.NavigationPayLoadMessage),

    ];
    this.state = {
      caseid:"",
      prrovisonalid: "",
      downReportModel: false,
      downloadclose:true,
      loading: false,
      errors:true,
      detailsData: { }, 
    };
    // Customizable Area End
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    runEngine.debugLog("Message Recived", message);
    const apiRequestCallId = message.getData(
      getName(MessageEnum.RestAPIResponceDataMessage)
    );
    const responseJson = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );
    const tokenExpire = responseJson.errors?.[0]?.token
    if (tokenExpire) {
      this.tokenExpire()
    }
    else if (apiRequestCallId === this.apiProvisionalSpecificationsAPIEndPoint) {
      if(!responseJson.error)
      this.setState({ detailsData: responseJson, loading: false, errors: false })
      else
        this.setState({
          loading: false,
          errors: true
        })
    }
    else  if (apiRequestCallId === this.apiDownloadReportAPIEndPoint) {
      if (responseJson.url)
        this.setState({ loading: false, downloadclose: false })
      else if (responseJson?.error) 
        this.setState({ downloadclose: false,loading: false })

    }
    // Customizable Area End
  }

  // Customizable Area Start

  async componentDidMount() {
    const loginToken = await getStorageData("token")
    if(!loginToken){
      this.tokenExpire()
    }   
    const prrovisonalid =  await getStorageData("Specid")
    this.setState({prrovisonalid:prrovisonalid})
    let caseid = await getStorageData("caseid")
    this.setState({caseid:caseid})
    this.getProvisonalDetail();      
   }

  downloadModelOpen = () => {
    this.setState({ downReportModel: true })
    this.generateReport();
  };

  downloadModelClose = () => {
    this.setState({ downReportModel: false })
  };

  generateReport = async () =>
  {
          const header = {
            token: await getStorageData("token"),
          };
          const caseid=this.state.caseid;
          const prrovisonalid = this.state.prrovisonalid;
          const requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
          );
          this.apiDownloadReportAPIEndPoint = requestMessage.messageId;

          requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            `bx_block_landingpage2/provisional_specifications/non_provisional_download_report?id=${prrovisonalid}&case_id=${caseid}`
          );
      
          requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
          );
      
          requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.validationApiMethodType
          );
          runEngine.sendMessage(requestMessage.id, requestMessage);
  
  }

   goBackToPublicationPage = async () => {
    removeStorageData("BackToDetails")
    this.props.navigation.goBack();
  };

  getProvisonalDetail = async () => {
    this.setState({ loading: true });
    const header = {
      token: await getStorageData("token"),
    };

    const prrovisonalid = this.state.prrovisonalid;
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.apiProvisionalSpecificationsAPIEndPoint = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `bx_block_landingpage2/provisional_specifications/${prrovisonalid}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  tokenExpire = async () => {
    removeStorageData("token");
    const message: Message = new Message(
      getName(MessageEnum.NavigationMessage)
    );
    message.addData(
      getName(MessageEnum.NavigationTargetMessage),
      "EmailAccountLoginBlock"
    );
    message.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    const raiseMessage: Message = new Message(
      getName(MessageEnum.NavigationPayLoadMessage)
    );
    message.addData(getName(MessageEnum.NavigationRaiseMessage), raiseMessage);
    this.send(message);
  };
  // Customizable Area End
}


// Customizable Area Start

 // Customizable Area End