import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { getStorageData, setStorageData , removeStorageData } from "../../../framework/src/Utilities";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { checkAccountLimitError } from "../../../blocks/utilities/src/ResolveCondition";
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  downloadModel: boolean
  clearTrigger:boolean
  patentId: string 
  detailsData: any;
  loading:boolean
  productNumbers:string[];
  isAccountLimitExceeded:boolean,
  isSimilarProducts:boolean
  // Customizable Area End
}

interface SS {
  id: any;
}

export default class ProductShowMappingController extends BlockComponent<
  Props,
  S,
  SS
> {
  apiShowMappingDetailsAPIEndPoint: string = "";
  apiGetProductDownloadMappingreportCallId:string = ""
  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [ 
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.NavigationPayLoadMessage),
    ];

    this.state = {
      productNumbers:[],
      clearTrigger:false,
      downloadModel: false,
      patentId: "",
      detailsData: { 
        mapping: []
       }, 
      loading:true,
      isAccountLimitExceeded:false,
      isSimilarProducts:false
    };
    // Customizable Area End
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    runEngine.debugLog("Message Recived", message);
    const apiRequestCallId = message.getData(
      getName(MessageEnum.RestAPIResponceDataMessage)
    );
    const responseJson = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );
    if (apiRequestCallId === this.apiShowMappingDetailsAPIEndPoint) {      
      if (responseJson?.error && responseJson?.errors?.length) {
        this.setState({ 
          loading: false,
          isAccountLimitExceeded: checkAccountLimitError(responseJson?.error,responseJson?.errors)
        })
      }
      else {
        const formattedDatas = this.transformData(responseJson);
        this.setState({ detailsData: formattedDatas })
        this.setState({ loading: false });
      }
    }
    else if (apiRequestCallId == this.apiGetProductDownloadMappingreportCallId) {
        if (responseJson) {
          this.setState({
            clearTrigger: true
          })
        }
    // Customizable Area End
  }
}

  // Customizable Area Start
  async componentDidMount() {
    const productid = await getStorageData("productid");
    const isSimilarProducts = await getStorageData("isSimilarProducts");
    this.setState({ patentId: productid ,productNumbers:productid,isSimilarProducts}, () => {
      removeStorageData("isSimilarProducts")
      if (this.state.patentId !== null) this.getProductDetailsAPI(this.state.patentId);
    });
  }

  transformData = (data: any) => {
    const mappings: any[] = [];

    let aggregateOverlapPercentage = 0;
    try {
    const extractOverlappingPercentage = (line: string): number => {

      const match = line.match(/(?:\d{1,3}(?:\.\d+)?|\.\d+)%/);  // Updated regex to reduce backtracking risk
      return match ? parseFloat(match[0]) : 0;  // Use match[0] instead of match[1]
    };
    const extractKeywords = (line: string): string[] => {
      return line.split(": ")[1]
        .split(",")
        .map((keyword: string) => keyword.trim());
    };
    const extractMatchingText = (startIndex: number): string => {
      const matchingLines: string[] = [];
      for (let j = startIndex + 3; j < data.mapping.length && !data.mapping[j].startsWith("Key Feature:"); j++) {
        const descriptionPart = extractDescriptionPart(data.mapping[j]);
        if (descriptionPart) {
          matchingLines.push(descriptionPart);
        }
      }
      return matchingLines.join(" ") || "N/A";
    };
    const extractDescriptionPart = (line: string): string => {
      if (line.includes("From Description:") || line.includes(":")) {
        return line.split(":")[1]?.trim() || "";
      }
      return "";
    };
    const processKeyFeature = (startIndex: number) => {
      const keyFeature = extractKeyFeature(data.mapping[startIndex]);
      const keywords = extractKeywords(data.mapping[startIndex + 1]);
      const overlappingPercentage = extractOverlappingPercentage(data.mapping[startIndex + 2]);
      return {
        keyFeature,
        keywords,
        overlappingPercentage,
        matchingText: {
          fromDescription: extractMatchingText(startIndex),
        }
      };
    };
    const extractKeyFeature = (line: string): string => {
      return line.split(": ")[1].trim();
    };
    const processMappings = () => {
      let index = 0;
      while (index < data.mapping.length) {
        const line = data.mapping[index];
        if (line.startsWith("Key Feature:")) {
          mappings.push(processKeyFeature(index));
          index += 5; // Move past the processed lines (current line + 4 more)
        } else if (line.includes("Aggregate Overlap Percentage:")) {
          aggregateOverlapPercentage = extractOverlappingPercentage(line);
          index++; // Move to the next line after processing the aggregate percentage
        } else index++; // Move to the next line for any other case
      }
    };
    processMappings();
  }
  catch(e) {
    console.log('error while parsing the mapping')
  }
    return {
      mapping: mappings,
      aggregateOverlapPercentage
    };
  };

  getProductDownloadReportIndividual = async (productNumbers: any) => {
    const header = {
      "Content-Type": "application/json",
      token: await getStorageData("token")
    };

    const Body = {
      "product_ids": [productNumbers],
      "case_id": await getStorageData("caseid")
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.apiGetProductDownloadMappingreportCallId = requestMessage.messageId;

    requestMessage.addData(getName(MessageEnum.RestAPIResponceEndPointMessage), `bx_block_advanced_search/products_download_report`);

    requestMessage.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(header));

    requestMessage.addData(getName(MessageEnum.RestAPIRequestBodyMessage), JSON.stringify(Body));

    requestMessage.addData(getName(MessageEnum.RestAPIRequestMethodMessage), 'POST');

    runEngine.sendMessage(requestMessage.id, requestMessage);
  }


  getProductDetailsAPI = async (productid: string) => {
    const header = {
      token: await getStorageData("token"),
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.apiShowMappingDetailsAPIEndPoint = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `/bx_block_landingpage2/mappings/keyword_mapping_for_product?product_id=${productid}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };
  downloadDetails = () => {
    this.downloadOpenModel()
    this.getProductDownloadReportIndividual(this.state.productNumbers)
  }
  downloadCloseModel = () => {
    this.setState({ downloadModel: false })
  };
  downloadOpenModel = () => {
    this.setState({ downloadModel: true })
  };
  goBackToProductDetail = () => {
    this.props.navigation.goBack();
  }
  // Customizable Area End
}
